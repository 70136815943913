const mutaions = {
  activeId(state, activeId) {
    state.activeId = activeId
  },
  showAside(state, showAside) {
    state.showAside = showAside
  },
  nodeInfo(state, nodeInfo) {
    state.nodeInfo = nodeInfo
  },
  userInfo(state, userInfo) {
    state.userInfo = userInfo
  },
  percent(state, percent) {
    state.percent = percent
  },
  stopUpload(state, stopUpload) {
    state.stopUpload = stopUpload
  },
  toDoMsg(state, toDoMsg) {
    state.toDoMsg = toDoMsg
  },
  toDoQuantity(state, toDoQuantity) {
    state.toDoQuantity = toDoQuantity
  },
  error401(state, error401) {
    state.error401 = error401
  },
}
export default mutaions
