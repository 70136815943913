import http from '@/api/http'

//消息列表
export const todoList = params => {
    return http.post(`/ks/task/v1/todo/list`, params)
}

//获取分类
export const helpTypeList = params => {
    return http.post(`/ks/help/v1/classify-list`, params)
}

//帮助列表
export const helpList = params => {
    return http.post(`/ks/help/v1/list`, params)
}
//添加次数
export const setTimes = params => {
    return http.post(`/ks/help/v1/times`, params)
}

//待办事务总数
export const getToDoNum = params => {
    return http.post(`/ks/task/v1/todo/total`, params)
}