import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import getters from './getters'
import mutaions from './mutations'
import modules from './modules'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  mutations: {
    ...mutaions
  },
  // actions: {
  // },
  modules: {
    ...modules
  },
})
export default store
