import http from '@/api/http';
// 结构树
export const getMenus = (params) => {
  return http.post(`/ks/menu/v1/menus/main-menus`, params);
};

// 密码登录
export const pwdLogin = (params) => {
  return http.post(`/account/login`, params);
};
// 手机号登录
export const phoneLogin = (params) => {
  return http.post(`/phone/login`, params, 'formData');
};

// 退出
export const logout = (params) => {
  return http.post(`/logout`, params);
};

// 手机号登录获取验证码
export const phoneLgnSendCode = (params) => {
  return http.post(`/ks/login/v1/phone/captcha`, params);
};

// 获取图片验证码
export const imgCaptcha = (params) => {
  return http.get(`ks/login/v1/image/captcha`, params);
};

// 忘记密码发送验证码
export const recoveryPwdCode = (params) => {
  return http.post(`/ks/account/v1/send-captcha`, params);
};
// 忘记密码校验手机号
export const verifyPhone = (params) => {
  return http.post(`/ks/account/v1/verify-phone`, params);
};

// 重置密码
export const forgetPwd = (params) => {
  return http.post(`/ks/account/v1/forget-pass`, params);
};

// 企业实名验证
export const realNameVerify = (params) => {
  return http.post(`/ks/register/v1/enterprise-verify`, params);
};

// 企业授权注册
export const authRegister = (params) => {
  return http.post(`/ks/register/v1/enterprise-register`, params);
};

// 企业授权注册
export const sendCode = (params) => {
  return http.post(`/ks/register/v1/send-captcha`, params);
};
