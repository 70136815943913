<template>
  <div id="app">
    <router-view />
    <template v-if="$route.path != '/'">
      <!-- 系统头部通知消息提醒 -->
      <TopMsg v-if="message" :msg="message"></TopMsg>
      <!-- 过期提醒 -->
      <ErrHint v-if="error401?.code" :error401="error401"></ErrHint>
    </template>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
export default {
  components: {},
  data() {
    return {
      message: '',
    };
  },
  computed: { ...mapState(['toDoMsg', 'error401']) },
  watch: {
    toDoMsg: {
      immediate: true,
      deep: true,
      handler(msg) {
        this.message = '';
        setTimeout(() => {
          if (msg) this.message = msg;
        }, 200);
      },
    },
  },
  created() {},
  mounted() {},

  methods: {},
};
</script>
<style>
#app {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
