<template>
  <div class="text-tooltip">
    <el-tooltip class="text-truncate" effect="dark" :disabled="isShowTooltip"  :placement="placement">
      <div slot="content" class="over-text-tooltip" style="max-width: 500px;">{{content}}</div>
      <div id="overFlow" class="over-flow" @mouseover="onMouseOver(refName)" @mouseout="isShowTooltip = true">
        <span :ref="refName" class="ellipsis">{{ content || '' }}</span>
      </div>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: 'JjTextTooltip',
  props: {
    // 显示的文字内容
    content: {
      type: String,
      default: ''
    },
    // 为页面文字标识（如在同一页面中调用多次组件，此参数不可重复）
    refName: {
      type: String,
      default: ''
    },
    // tooltip出现的位置
    placement: {
      type: String,
      default: 'top'
    },
    // 展示文字的行数
    lineClamp: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      isShowTooltip: true
    }
  },
  mounted() {
    //判断宽度是否需要出现toooltip
    this.checkTooltipVisibility()
    //优化resize
    window.addEventListener('resize', this.checkTooltipVisibility)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkTooltipVisibility)
  },
  methods: {
    onMouseOver(refName) {
      //这里的refName是子组件的refName不是从父组件传来的，所以需要在@mouseover里传递，使得页面能找到这个dom
      let parentWidth = this.$refs[refName].parentNode.offsetWidth
      let contentWidth = this.$refs[refName].offsetWidth
      let parentHeight = this.$refs[refName].parentNode.offsetHeight
      let contentHeight = this.$refs[refName].offsetHeight
      let flag = this.lineClamp > 1 && parentHeight < contentHeight

      if (flag) {
        // 多行文字溢出开启tooltip功能
        this.isShowTooltip = false
      } else {
        // 判断是否开启tooltip功能
        contentWidth > parentWidth ? (this.isShowTooltip = false) : (this.isShowTooltip = true)
      }
    },
    checkTooltipVisibility() {
      const spanEl = this.$refs[this.refName]
      if (spanEl) {
        const parentWidth = spanEl.parentNode.offsetWidth
        const contentWidth = spanEl.offsetWidth
        const spanElStyle = spanEl.parentNode.style

        if (this.lineClamp > 1) {
          // 多行
          spanElStyle['white-space'] = 'wrap'
          spanElStyle['-webkit-box-orient'] = 'vertical'
          spanElStyle['display'] = '-webkit-box'
          spanElStyle['-webkit-line-clamp'] = this.lineClamp
        } else {
          // 单行
          this.isShowTooltip = contentWidth > parentWidth
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.text-tooltip {
  width: 100%;
  height: 100%;

  .over-flow {
    width: 100%;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: default;
  }
}
</style>
