import SparkMD5 from 'spark-md5'
import { shardingInit, shardingUpload, mergeFile, lessThan5m, cancelUpload } from '@/api/public'
import store from '@/store'
let chunkSize = 5 * 1024 * 1024
let cancelId = ''
/**
 * 上传文件
 */
export const uploadFile = async (File, param) => {
  store.commit('percent', 2)
  store.commit('stopUpload', false)
  return new Promise(async (resolve, reject) => {
    const fileSize = File.size
    //计算当前选择文件需要的分片数量
    const chunkCount = Math.ceil(fileSize / chunkSize)
    //获取文件md5
    const fileMd5 = await getFileMd5(File.raw, chunkCount)
    // 小于5M走普通上传 否则切片
    if (fileSize < chunkSize) {
      resolve(lessThan5(File, fileMd5))
      return
    } else {
      const initUploadParams = {
        md5: fileMd5, //文件的md5
        fileName: File.name, //文件名
        chunk: chunkCount, //分片的总数量
        fileSize: fileSize,
      }
      // 调用后端检查文件上传情况接口
      await shardingInit(initUploadParams).then(async res => {
        let data = res.data
        cancelId = data.uploadId
        //切片未成功
        if (!data.url && (!data.uploadUrlList || data.uploadUrlList.length <= 0)) {
          resolve(false)
          return
        }
        //上传过的文件就秒传
        if (data.url && !data.uploadUrlList) {
          resolve(data)
          return
        }
        // 获取后端返回的分片数字的数组
        let uploadList = data.uploadUrlList
        if (uploadList && uploadList.length > 0) {
          // 循环调用上传
          for (let i = 0; i < uploadList.length; i++) {
            //切换页面终止上传
            if (store.state.stopUpload) break
            //分片开始位置
            let start = uploadList[i] * chunkSize
            //分片结束位置
            let end = Math.min(start + chunkSize, fileSize)
            //取文件指定范围内的byte，从而得到分片数据
            let _chunkFile = File.raw.slice(start, end)
            //上传参数
            const formdata = new FormData()
            formdata.append('chunk', uploadList[i])
            formdata.append('uploadId', data.uploadId)
            formdata.append('file', _chunkFile)
            formdata.append('md5', data.md5)
            // 通过await实现顺序上传
            await getMethods(formdata)
          }
          // 文件上传完毕，请求后端合并文件并传入参数
          if (!store.state.stopUpload) {
            resolve(composeFile(data.uploadId, File.name, data.md5))
          }
        }
      })
    }
  })
}

/**
 * 取消上传
 */
export const cancel = async () => {
  if (cancelId) {
    return new Promise(async (resolve, reject) => {
      await cancelUpload({ uploadId: cancelId })
        .then(() => {
          resolve(true)
        })
        .catch(err => {
          resolve(true)
        })
    })
  }
}

/**
 * 小于5M上传
 * @param File 上传文件的参数
 */

export const lessThan5 = async (File, md5) => {
  const formdata = new FormData()
  formdata.append('file', File.raw)
  formdata.append('fileSize', File.size)
  formdata.append('md5', md5)
  const { code, data } = await lessThan5m(formdata)
  if (code == 200) {
    return data
  }
}
/**
 * 切片上传方法
 * @param formdata 上传文件的参数
 */

export const getMethods = async formdata => {
  const { code, data } = await shardingUpload(formdata)
  if (code == 200) {
    let percent = data == 100 ? data - 0.1 : data < 5 ? 10 : data
    store.commit('percent', Number(percent.toFixed(2)))
  }
}
/**
 * 请求后端合并文件
 */
export const composeFile = async (uploadId, fileName, md5) => {
  let params = {
    uploadId: uploadId,
    fileName: fileName,
    md5: md5,
  }
  const { code, data } = await mergeFile(params)
  if (code == 200) {
    return data
  }
}
/**
 * 获取文件MD5
 * @param file
 * @returns {Promise<unknown>}
 */
export const getFileMd5 = (file, chunkCount) => {
  return new Promise((resolve, reject) => {
    let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
    let chunks = chunkCount
    let currentChunk = 0
    let spark = new SparkMD5.ArrayBuffer()
    let fileReader = new FileReader()

    fileReader.onload = function (e) {
      spark.append(e.target.result)
      currentChunk++
      if (currentChunk < chunks) {
        loadNext()
      } else {
        let md5 = spark.end()
        resolve(md5)
      }
    }
    fileReader.onerror = function (e) {
      reject(e)
    }
    function loadNext() {
      let start = currentChunk * chunkSize
      let end = start + chunkSize
      if (end > file.size) {
        end = file.size
      }
      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
    }
    loadNext()
  })
}
