<template>
  <div>
    <!-- 过期提醒 -->
    <el-dialog
      :title="code == 401 ? '提 示' : '操作失败'"
      :visible.sync="isOpen"
      :width="code == 401 ? '360px' : '500px'"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="code == 401 ? false : true"
      :before-close="(e) => close(false)"
    >
      <div class="text" v-if="code == 401">
        <i class="el-icon-warning"></i>
        <p>当前账号长时间未操作，请重新登录！</p>
      </div>
      <div v-if="code == 500">
        <el-descriptions :column="1" border>
          <el-descriptions-item label="失败原因">
            <p style="width: 100%">
              <JjTextTooltip :lineClamp="5" :content="msg"></JjTextTooltip>
            </p>
          </el-descriptions-item>
          <el-descriptions-item label="操作流水号">
            <span class="curId"
              >{{ curId }}
              <i
                class="el-icon-copy-document"
                v-clipboard:copy="curId"
                v-clipboard:success="onCopy"
              ></i
            ></span>
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <span slot="footer" class="dialog-footer" v-if="code == 401">
        <el-button type="primary" @click="close(true)" size="mini"
          >重新登录</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'ErrHint',
  components: {},
  props: {
    error401: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: false,
      code: null,
      msg: null,
      curId: null,
    };
  },
  computed: {},
  watch: {
    error401: {
      immediate: true,
      deep: true,
      handler(obj) {
        console.log('obj', obj);
        if (obj?.code) this.isOpen = true;
        this.code = obj.code;
        this.msg = obj.msg;
        this.curId = obj.requestId;
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    close(val) {
      this.$store.commit('error401', null);
      if (val) {
        localStorage.clear();
        sessionStorage.clear();
        this.$router.replace('/');
      }
    },
    onCopy() {
      this.$message.success('已复制！');
    },
    // login() {
    //   window.location.reload(true);
    // },
  },
};
</script>
<style scoped lang='scss'>
.text {
  display: flex;
  i {
    color: #ffa801;
    font-size: 24px;
  }
  p {
    font-size: 16px;
    margin-left: 8px;
  }
}
.curId {
  // text-align: center;
  font-size: 16px;
  color: #1e90ff;
  margin-top: 20px;
}
::v-deep .is-bordered-label {
  white-space: nowrap;
  color: #333;
}
::v-deep .el-dialog__title {
  color: #f56c6c !important;
}
</style>