import http from '@/api/http';
/**
 *
 *  详情api
 *
 */
// 获取详情字段
export const formFields = (params) => {
  return http.post(`/ks/form/v1/form/fields`, params);
};

// 获取普通下拉数据
export const getPullDown = (params, code) => {
  let url = ``;
  //监管单位下拉数据
  if (code == 'regulatorId') {
    url = '/ks/dict/v1/supervision';
  } else {
    // 获取普通下拉数据
    url = `/ks/dict/v1/dictionaries`;
  }
  return http.post(url, params);
};

// 获取区域下拉数据
export const getAreaPullDown = (params, code) => {
  let url = ``;
  // 资质、证书级联
  if (code == 'zsName' || code == 'xlName') {
    url = `/ks/dict/v1/certificate`;
  } else {
    // 获取区域下拉数据
    url = `/ks/dict/v1/region`;
  }
  return http.post(url, params);
};

/** 获取区域 **/
export const getRegion = (params) => {
  return http.post(`/ks/dict/v1/practice/region`, params);
};

/** 获取附件 **/
export const getFilesList = (params) => {
  return http.post(`/ks/file/v1/file-list`, params);
};

// 保存附件
export const filesSave = (params) => {
  return http.post(`/ks/file/v1/file-save`, params);
};
// 删除附件
export const filesDel = (params) => {
  return http.post(`/ks/file/v1/file-del`, params);
};

//抽取辖区下拉
export const getXiaQuPullDown = (params) => {
  return http.post(`/ks/dict/v1/jurisdiction`, params);
};

//可选标段列表
export const getBdList = (params) => {
  return http.post(`/ks/bd/v1/bd-list`, params);
};

//抽取专业
export const getExtractZyList = (params) => {
  return http.post(`/ks/dict/v1/professions`, params);
};

//分片初始化
export const shardingInit = (params) => {
  return http.post(`/ks/upload/v1/upload-task`, params);
};

//分片上传
export const shardingUpload = (params) => {
  return http.post(`/ks/upload/v1/chunk-upload`, params, 'formData');
};

//取消上传接口
export const cancelUpload = (params) => {
  return http.post(`/ks/upload/v1/upload-cancel`, params);
};

//完成上传 合并
export const mergeFile = (params) => {
  return http.post(`/ks/upload/v1/file-merge`, params);
};

//上传小于5M得附件
export const lessThan5m = (params) => {
  return http.post(`/ks/upload/v1/upload`, params, 'formData');
};

// 删除附件
export const fileDelete = (params) => {
  return http.post(`/ks/portal/v1/file-delete`, params);
};

// 日志
export const logRecord = (params) => {
  return http.get(`/ks/log/v1/audit/logs/${params}`);
};
