<template>
  <div class="jj-details-form">
    <el-form
      :model="formModel"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="120px"
      :show-message="true"
      :validate-on-rule-change="false"
      class="dynamic-form"
      @submit.native.prevent
    >
      <el-row :gutter="24">
        <el-col
          :span="item.width"
          v-for="(item, index) in formItems"
          :key="item.code"
          :class="{ 'label-c': item.labelColer }"
        >
          <el-form-item class="form-item" :prop="item.code" v-show="item.show">
            <template slot="label">
              <span>
                {{ item.name }}
                <el-tooltip
                  effect="dark"
                  v-if="item.tips"
                  :content="item.tips"
                  placement="top"
                >
                  <i class="el-icon-warning-outline label-tip"></i>
                </el-tooltip>
              </span>
            </template>

            <el-input
              v-if="item.type === 'text' || item.type === 'textarea'"
              v-model="formModel[item.code]"
              :placeholder="item.edit ? '请输入' + item.name : ''"
              :disabled="!item.edit || isEdit"
              :type="item.type"
              autosize
              @input="inputBlur(item)"
            >
              <template v-if="item.append" slot="append">{{
                item.append
              }}</template>
            </el-input>

            <el-radio-group
              v-if="item.type == 'radio'"
              v-model="formModel[item.code]"
              :disabled="!item.edit || isEdit"
              @input="(e) => radioInput(e, item)"
            >
              <el-radio :label="'男'">男</el-radio>
              <el-radio :label="'女'">女</el-radio>
            </el-radio-group>

            <el-radio-group
              v-if="item.type == 'radio-1'"
              v-model="formModel[item.code]"
              :disabled="!item.edit || isEdit"
              @input="(e) => radioInput(e, item)"
            >
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>

            <el-switch
              v-if="item.type == 'switch'"
              v-model="formModel[item.code]"
              :disabled="!item.edit || isEdit"
            >
            </el-switch>

            <el-select
              v-if="
                item.type == 'select' ||
                item.type == 'selectInt' ||
                item.type == 'select-multiple'
              "
              v-model="formModel[item.label || item.code]"
              :collapse-tags="item.tags"
              :multiple="item.type == 'select-multiple'"
              :placeholder="item.edit ? '请选择' + item.name : ''"
              :disabled="!item.edit || isEdit"
              @visible-change="(e) => selectDropDown(e, item)"
              :filterable="item.type == 'selectInt'"
              :allow-create="item.type == 'selectInt'"
              :default-first-option="item.type == 'selectInt'"
            >
              <el-option
                v-for="it in item.options"
                :key="it.value"
                :label="it.label"
                :value="it.label"
                @click.native="selectChange(item, it)"
              >
              </el-option>
            </el-select>

            <el-date-picker
              v-if="item.type === 'date'"
              v-model="formModel[item.code]"
              :disabled="!item.edit || isEdit"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :placeholder="item.edit ? '选择日期' : ''"
              style="width: 100%"
              @change="(e) => dateOnClick(e, item)"
            ></el-date-picker>

            <el-date-picker
              v-if="item.type === 'datetime'"
              v-model="formModel[item.code]"
              :disabled="!item.edit || isEdit"
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              :placeholder="item.edit ? '选择日期' : ''"
              style="width: 100%"
            ></el-date-picker>

            <el-date-picker
              v-if="item.type === 'daterange'"
              v-model="formModel[item.code]"
              :disabled="!item.edit || isEdit"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 100%"
            ></el-date-picker>

            <el-cascader
              v-if="item.type === 'cascader'"
              clearable
              style="width: 100%"
              :options="item.options"
              :props="{ checkStrictly: item.cS, label: 'name', value: 'id' }"
              :ref="'cascaderRef' + index"
              :disabled="!item.edit || isEdit"
              v-model="formModel[item.code]"
              :placeholder="item.edit ? `请选择${item.name}` : ''"
              @visible-change="(e) => cascaderDropDown(e, item)"
              @change="cascaderChange(item, index)"
            >
            </el-cascader>

            <!-- 富文本编辑器 -->
            <JjEditorTool
              v-if="item.type === 'rich-text'"
              :key="timer"
              :editorParams="editorParams(item)"
              :excludeKeys="excludeKeys"
              @changeHtml="(e) => changeHtml(e, item)"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
// import { pullDown } from "@/components/pullDown.js";
import { getPullDown, getAreaPullDown } from '@/api/public';
export default {
  name: 'JjForm',
  components: {},
  data() {
    return {
      isEdit: this.edit,
      rules: {},
      options: [],
      formModel: {},
      timer: new Date().getTime(),
      excludeKeys: [
        // 编辑器要屏蔽的工具
        'group-image',
        'group-video',
        // 'color',
        'bgColor',
        'emotion',
        'codeBlock',
        'blockquote',
        'group-more-style',
        'insertLink',
        'todo',
      ],
    };
  },
  props: {
    edit: {
      type: Boolean,
      default: true,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    formData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {},
  watch: {
    edit: {
      immediate: true,
      handler(v) {
        this.isEdit = v;
      },
    },
    formData: {
      deep: true,
      immediate: true,
      handler(d = {}) {
        this.formModel = d;
      },
    },
    fields: {
      deep: true,
      immediate: true,
      handler(v = []) {
        this.formItems = JSON.parse(JSON.stringify(v));
        this.formItems.map((it) => {
          if (it.parentNode) {
            it.show = this.formModel[it.code] ? true : false;
            it.required = this.formModel[it.code] ? true : false;
          }
        });

        this.setRuleList();
        this.getSelectOpts();
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    getSelectOpts() {
      this.formItems.forEach((item, idx) => {
        item.options = [];
        if (item.type == 'cascader' && item.show) {
          this.cascaderDropDown(true, item);
        }
        //值为value时回显有问题  需要初始化调用接口 【监管单位】
        if (item.value) {
          this.selectDropDown(true, item);
        }
      });
    },
    dateOnClick(e, item) {
      this.$emit('dateOnClick', e, item);
    },
    inputBlur(item) {
      if (item.blur) this.$emit('inputBlur', item);
    },
    //点击单选  radio-1 用于显示隐藏 字段
    radioInput(e, item) {
      if (item.type == 'radio-1') {
        this.formItems.map((it) => {
          if (it.parentNode == item.code) {
            it.show = e == 1 ? true : false;
            it.required = e == 1 ? true : false;
            this.$set(this.formModel, [it.code], '');
            if (e == 1) this.setRuleList();
            if (e == 0) this.rules[it.code] = [];
            this.formModel = JSON.parse(JSON.stringify(this.formModel));
          }
        });
      }
    },
    // select普通下拉
    async selectDropDown(e, item) {
      if (e) {
        const idx = this.formItems.findIndex((v) => v.code === item.code);
        if (idx === -1) return;
        if (
          !item.options ||
          (item.options && item.options.length <= 0) ||
          item.pid
        ) {
          let params = { id: item.code };
          if (item.pid) {
            //监管单位需要pid
            let pid = this.formModel[item.pid];
            params.pid = pid[pid.length - 1];
          }
          const { data } = await getPullDown(params, item.code);
          item.options = data;
          this.$set(this.formItems, idx, item);
          this.$forceUpdate();
        }
      }
    },

    selectChange(item, it) {
      if (item.label) {
        this.formModel[item.code] = it.value;
        this.formModel[item.label] = it.label;
      }
    },
    //cascader数值改变
    cascaderChange(item, index) {
      let cascader = this.$refs['cascaderRef' + index][0];
      const label = cascader.getCheckedNodes()[0].pathLabels.join(' / ');
      if (item.label) this.formModel[item.label] = label;
      // 清空下级
      if (item.childCode || item.childLabel) {
        if (item.childCode) this.formModel[item.childCode] = '';
        if (item.childLabel) this.formModel[item.childLabel] = '';
      }
    },
    //cascader区域下拉
    async cascaderDropDown(e, item) {
      if (e) {
        const idx = this.formItems.findIndex((v) => v.code === item.code);
        if (idx === -1) return;
        if (!item.options || (item.options && item.options.length <= 0)) {
          const { data } = await getAreaPullDown(
            { pid: item.dicCode },
            item.code
          );
          item.options = data;
          this.$set(this.formItems, idx, item);
          this.$forceUpdate();
        }
      }
    },
    editorParams(item) {
      let obj = {
        editText: '', // 富文本内容
        placeholder: '请填写内容', // 占位内容
        height: '450px', // 富文本最小高度
        readOnly: false, // 是否只读
        autoFocus: false, // 自动获取焦点
        maxLength: '',
      };
      this.$nextTick(() => {
        obj.readOnly = !item.edit;
        obj.editText = this.formModel[item.code] || '';
      });
      return obj;
    },
    // 富文本输入值改变
    changeHtml(html, item) {
      let str = html == '<p><br></p>' ? '' : html;
      this.formModel[item.code] = str;
      this.editorParams(item);
    },
    setRuleList() {
      const resolveRules = {};
      this.formItems.forEach((item) => {
        if (item.show && item.required) {
          resolveRules[item.code] = [
            {
              required: true,
              validator: (rule, value, cd) => {
                if (value instanceof Array && value.length <= 0)
                  value = value.join('');
                if (!value && !item.regex) {
                  return cd(new Error(`${item.name}必填`));
                }
                if (item.regex && !new RegExp(item.regex).test(value)) {
                  return cd(new Error(item.prompt));
                } else {
                  return cd();
                }
              },
              trigger: ['blur', 'change'],
            },
          ];
          this.rules = { ...this.rules, ...resolveRules };
        }
      });
    },
    submitForm(arr) {
      this.$refs['form'].validate((valid, err) => {
        this.formItems.forEach((item) => {
          item.labelColer = false;
          for (const key in err) {
            if (item.code == key) {
              item.labelColer = true;
              arr.push(item.name);
              this.$forceUpdate(); //更新lable颜色
            }
          }
        });
      });
      return arr.join(' 、');
    },
    validate(callback) {
      this.$refs.form.validate((valid) => {
        callback(valid);
      });
    },
  },
};
</script>
<style scoped lang="scss">
.jj-details-form {
  width: 100%;
  height: 100%;
  .label-c {
    .is-error {
      span {
        color: #ea2027;
      }
    }
  }
}

::v-deep .el-form-item {
  margin-bottom: 10px !important;
}
::v-deep .el-form-item__error {
  padding-top: 0 !important;
  display: none;
}
::v-deep .el-form-item__label {
  span {
    color: #333;
  }
}
::v-deep .el-select__tags-text {
  color: #666 !important;
}
::v-deep .el-textarea.is-disabled .el-textarea__inner {
  background-color: #eee;
}
::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #eee;
}
::v-deep .el-form-item.is-error .el-input__inner {
  border-color: #f56c6c !important;
}
</style>
