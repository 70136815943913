import axios from "axios";
import { setStore, getStore, removeStore } from "@/util/store";
import { Message } from 'element-ui'
import router from '@/router/index'
import store from '@/store'

const request = axios.create({
    // baseURL: 'http://192.168.254.105:8080',
    timeout: 300000,
    headers: { 'Content-Type': 'application/json' },

})

/* request拦截器 */
// 请求发出去前的拦截
request.interceptors.request.use(
    config => {
        config.headers.Accept = 'application/json;charset=UTF-8'

        let token = getStore({ name: 'token' }) // 获取token
        // 每次请求将token加入到请求头中
        token && (config.headers.Authorization = 'Bearer ' + token)
        return config
    }, err => {
        // Toast.fail('网络状态差');// 报错则弹出网络状态差
        Message.warning('网络状态差')
        return Promise.reject(err)
    }
)

// 服务器返回请求时的拦截
request.interceptors.response.use(
    response => {
        let rfhtoken = response.headers.refreshtoken
        if (rfhtoken) setStore({ name: 'token', content: rfhtoken });

        let res = {
            code: response.data.code,
            data: response.data.data,
            msg: response.data.message || response.data,
        }
        return res //将主体内容返回  
    }, error => {
        let err = {
            code: error.response.data.code,
            data: error.response.data.data,
            msg: error.response.data.message || error.response.data.error,
            requestId: error.response.data.requestId
        }


        // 不在首页弹框
        if (router.history.current.path != '/') {
            if (err.code === 401 || err.code === 500) {
                // 触发退出或登录提示弹框
                store.commit('error401', err)
            } else {
                Message.warning(err.msg)
            }
        }

        return err //将主体内容返回  
        // return Promise.reject(error.response.data.message || error)
    })


export default request





