<template>
  <div class="main" :class="{ 'collapse-w': showAside }">
    <el-menu
      :collapse-transition="false"
      :default-active="activeId"
      :collapse="showAside"
      :unique-opened="true"
      class="el-menu-vertical-demo"
      background-color="transparent"
      text-color="#ffffffb3"
      active-text-color="#fff"
      :class="{ 'submenu-bg': showAside }"
    >
      <template v-for="(item, index) in MenuS">
        <el-menu-item
          :class="{ 'back-col': item.id === activeId }"
          :key="index"
          :index="item.id"
          v-if="item.children.length <= 0"
          @click="curMenu(item)"
        >
          <i :class="item.icon"></i>
          <span class="name" slot="title">{{ item.name }}</span>
          <i
            v-if="item.id === activeId && !showAside"
            class="el-icon-caret-right"
          ></i>
        </el-menu-item>

        <el-submenu
          v-if="item.children.length > 0"
          :key="index"
          :index="item.id"
        >
          <template slot="title">
            <i :class="item.icon"></i>
            <span class="name" slot="title">{{ item.name }}</span>
          </template>

          <el-menu-item
            style="margin: 6px 0"
            v-for="(it, inx) in item.children"
            :class="{ 'back-col': it.id === activeId }"
            :key="inx"
            :index="it.id"
            @click="curMenu(it)"
          >
            <!-- <span class="d"></span> -->
            <i :class="it.icon"></i>
            <span slot="title">{{ it.name }}</span>
            <i
              v-if="it.id === activeId && !showAside"
              class="el-icon-caret-right r-i"
            ></i>
          </el-menu-item>
        </el-submenu>
      </template>
    </el-menu>
    <div class="menu-footer">
      <div class="fold-icon" @click="toggle">
        <i :class="!showAside ? 'el-icon-s-fold' : 'el-icon-s-unfold'"></i>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { setStore, getStore, removeStore } from '@/util/store';
export default {
  components: {},
  props: {},
  data() {
    return {
      // activeId: "",
      showAside: false,
      logoBlue: require('@/assets/images/logo/logo_blue.png'),
      // MenuS: menu.adminMenu,
      MenuS: [],
    };
  },
  computed: {
    ...mapGetters(['menus', 'activeId']),
  },
  watch: {
    menus(menu) {
      this.MenuS = menu;
    },
  },
  created() {
    this.MenuS = getStore({ name: 'menus' });
  },
  mounted() {},
  methods: {
    curMenu(item) {
      // let curNode = getStore({ name: "nodeInfo" });
      // //防止第二次点击 监听不到nodeInfo  导致不刷新页面问题
      // if (curNode && curNode.id == item.id) return;

      // 清除表格行数据
      removeStore({ name: 'tableRow' });
      removeStore({ name: 'corpBasicNav' });
      removeStore({ name: 'toDoNav' });
      removeStore({ name: 'parentPath' });

      setStore({ name: 'activeId', content: item.id });
      this.$store.commit('activeId', item.id);

      setStore({ name: 'nodeInfo', content: item });
      this.$store.commit('nodeInfo', item);
      let path = item.routerPath;
      if (path?.includes('/:node')) {
        path = path.replace('/:node', `/${item.name}`);
      }
      this.$router.push({ path });
    },
    toggle() {
      this.showAside = !this.showAside;
    },
  },
};
</script>
<style scoped lang="scss">
.collapse-w {
  width: 60px !important;
  transition: 0.3s;
}
.main {
  width: 220px;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  .menu-footer {
    height: 56px;
    width: 100%;
    display: flex;
    align-items: center;
    .fold-icon {
      width: 60px;
      height: 100%;
      @extend %flex-center;
      i {
        font-size: 20px;
        color: #999;
        transition: 0.3s;
      }
      i:hover {
        color: #1e90ff;
        transition: 0.3s;
      }
    }
  }
  .name {
    font-size: 16px !important;
  }

  .el-menu {
    width: 100%;
    height: 100%;
    border-right: none;
    ::v-deep .el-submenu__icon-arrow {
      font-size: 16px;
    }
    .el-menu-item {
      position: relative;
      margin: 0 !important;
      .el-icon-caret-right {
        font-size: 28px;
        position: absolute;
        right: -13px;
        top: 14px;
        color: #1e90ff;
        margin: 0 !important;
      }
      .r-i {
        top: 12px !important;
      }
    }
    ::v-deep .el-menu--inline {
      background-color: #0f2a44 !important;
    }
    ::v-deep .el-submenu__title:hover {
      background: transparent !important;
      color: #fff !important;
      transition: 0.3s;
    }
    .el-menu-item:hover {
      background: transparent !important;
      color: #fff !important;
      transition: 0.3s;
    }
    .back-col:hover {
      background: #1e90ff !important;
    }
    .back-col {
      background-color: #1e90ff !important;
      position: relative;
      .d {
        position: absolute;
        width: 6px;
        height: 6px;
        top: 23px;
        left: 25px;
        background-color: #fff;
        border-radius: 50px;
      }
    }
  }
}
::v-deep .submenu-bg {
  .is-active {
    background: #1e90ff;
    i {
      color: #fff !important;
    }
  }
}
</style>
