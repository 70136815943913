<template>
  <div class="tab-head">
    <div class="title">{{ tabTitle||nodeInfo.name }}</div>
    <div class="actions">
      <div class="header—btn">
        <div v-for="(item, index) in headBtn" :key="index">
          <div
            class="btn"
            :style="{ backgroundColor: item.color }"
            @click="headFun(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "JjTabHead",
  components: {},
  props: {
    headBtn: {
      type: Array,
      default: () => [],
    },
    tabTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      listTopBtn: [{ name: "新增", code: "add", show: true, color: "#67C23A" }],
    };
  },
  computed: { ...mapState(["nodeInfo"]) },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    headFun(row) {
      this.$emit("headFun", row);
      // const cb = this[code];
      // cb && cb(row);
    },
  },
};
</script>
<style scoped lang='scss'>
.tab-head {
  display: flex;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #eee;
  padding: 10px 14px;
  background-color: #fff;
  .actions {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .title {
    font-size: 18px;
    font-weight: 600;
    margin-right: 20px;
  }
  .header—btn {
    display: flex;
    .btn {
      margin-right: 18px;
      padding: 2px 14px;
      border-radius: 2px;
      color: #fff;
      background-color: #1e90ff;
      cursor: pointer;
    }
  }
  .video {
    font-size: 26px;
    color: #1e90ff;
  }
}
</style>