const getters = {
  menus: state => state.common.menus,
  activeId: state => state.activeId,
  showAside: state => state.showAside,
  nodeInfo: state => state.nodeInfo,
  userInfo: state => state.userInfo,
  token: state => state.user.token,
  toDoQuantity: state => state.toDoQuantity,
  error401: state => state.toDoQuantity,
}

export default getters
