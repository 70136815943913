export default [
  {
    path: '/',
    title: '登录',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/pages/login/index.vue'),
    meta: { noAuth: true }
  },
  {
    path: '/fileView',
    title: '新闻/政策',
    name: 'fileView',
    component: () => import(/* webpackChunkName: "login" */ '@/pages/login/fileView.vue'),
    meta: { noAuth: true }
  },
  {
    path: '/help',
    title: '帮助',
    name: 'help',
    component: () => import(/* webpackChunkName: "help" */ '@/pages/help/index.vue'),
  },
]