<template>
  <div class="cdyy-mian">
    <el-descriptions :column="12" border>
      <el-descriptions-item
        label-class-name="my-label"
        v-for="(item, index) in formItems"
        :key="index"
        :label="item.name"
        :span="item.width"
      >
        <el-input
          v-if="item.type == 'input'"
          v-model="formModel[item.code]"
          :placeholder="item.edit ? '请输入' + item.name : ''"
          :id="item.code"
          :disabled="!item.edit"
        >
        </el-input>

        <el-radio-group
          v-if="item.type == 'radio'"
          v-model="formModel[item.code]"
          :disabled="!item.edit"
        >
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>

        <el-select
          v-if="item.type == 'select' || item.type == 'select-multiple'"
          v-model="formModel[item.bm || item.code]"
          :multiple="item.type == 'select-multiple'"
          :placeholder="item.edit ? '请选择' + item.name : ''"
          :id="item.code"
          :disabled="!item.edit"
          @visible-change="(e) => openDropDown(e, item)"
        >
          <el-option
            v-for="it in item.options"
            :key="it.value"
            :label="it.label"
            :value="it.value"
          >
          </el-option>
        </el-select>

        <el-date-picker
          v-if="item.type === 'date'"
          v-model="formModel[item.code]"
          :disabled="!item.edit"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          :placeholder="item.edit ? '选择日期' : ''"
          style="width: 100%"
        ></el-date-picker>

        <el-date-picker
          v-if="item.type === 'datetime'"
          v-model="formModel[item.code]"
          :disabled="!item.edit"
          type="datetime"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          :placeholder="item.edit ? '选择日期' : ''"
          style="width: 100%"
        ></el-date-picker>
        <template v-if="item.type === 'text'">
          <span>{{ formModel[item.code] }}</span>
        </template>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { getPullDown, getXiaQuPullDown, getBdList } from "@/api/public";
export default {
  name: "JiCardDesList",
  components: {},
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    formData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formItems: [],
      formModel: {},
      bidOpenLists: [],
      curBid: {},
      loading: false,
    };
  },
  computed: { },
  watch: {
    formData: {
      deep: true,
      immediate: true,
      handler(data = {}) {
        this.formModel = data;
      },
    },
    fields: {
      immediate: true,
      deep: true,
      handler(n) {
        this.formItems = n;
      },
    },
  },
  created() {
    this.getSelectOpts();
  },
  mounted() {},
  methods: {
    getSelectOpts() {
      let type = ["select", "select-multiple"];
      this.formItems.forEach((item, idx) => {
        if (item && type.includes(item.type)) {
          item.options = [];
          this.openDropDown(true, item);
        }
      });
    },

    //点击'1'开标室'2'评标室
    clickBidOpen(item, val) {
      this.curBid = item;
    },

    async openDropDown(e, item) {
      if (e) {
        const idx = this.formItems.findIndex((v) => v.code === item.code);
        if (idx === -1) return;
        if (!item.options || (item.options && item.options.length <= 0)) {
          const { data } = await getPullDown({ id: item.code });
          item.options = data;
          this.$set(this.formItems, idx, item);
        }
      }
    },
  },
};
</script>
<style scoped lang='scss'>
.cdyy-mian {
  .card-content {
    height: 600px;
    overflow: auto;
  }
  .card-box {
    // flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 2fr));
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    overflow: auto;

    .item-box:hover {
      box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
      transition: 0.3s;
    }
    .item-box-s {
      background-color: #1e90ff !important;
      color: #fff !important;
    }
    .disabled-s {
      background-color: #e4dfdf !important;
      border: 1px solid #e4dfdf !important;
      color: #666 !important;
      cursor: not-allowed !important;
      border: none !important;
    }
    .item-box {
      width: 100%;
      // height: 60px;
      padding: 6px 14px;
      transition: 0.3s;
      border-radius: 50px;
      // color: #333;
      border: 1px solid #8abef1;
      // background-color: #f2f2f2;
      display: flex;
      align-items: center;
      justify-content: center;
      // font-size: 18px;
      cursor: pointer;
    }
  }
  ::v-deep .my-label {
    background: #e9f2fa !important;
    color: #333;
    // font-weight: ;
  }
  .bsBtn {
    color: #1e90ff;
    cursor: pointer;
  }
  .el-select {
    width: 100%;
  }

  ::v-deep .el-descriptions-item__cell {
    border: 1px solid #b4d8fd !important;
  }
  // ::v-deep .el-descriptions-item__cell {
  //   padding: 8px 12px !important;
  // }
  ::v-deep .is-bordered-label {
    //   width: 130px;
    text-align: right !important;
  }
  ::v-deep .el-descriptions-item__cell {
    min-width: 120px;
  }
}
</style>