// 项目管理
export let zszb = [
    {
        path: 'zb-gather',
        title: '指标采集',
        name: 'zb-gather',
        component: () => import(/* webpackChunkName: "zb-gather" */ '@/pages/zszb/zbGather/index.vue'),
    },
    {
        path: 'zb-list',
        title: '指标展示',
        name: 'zb-list',
        component: () => import(/* webpackChunkName: "zb-list" */ '@/pages/zszb/zbList/index.vue'),
    },
    {
        path: 'zb-detail',
        title: '指标详情',
        name: 'zb-detail',
        component: () => import(/* webpackChunkName: "zb-detail" */ '@/pages/zszb/zbDetails/index.vue'),
    },
]