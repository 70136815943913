var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jj-table"},[_c('el-table',{attrs:{"data":_vm.tableData,"header-cell-style":{
      borderColor: '#b9b8b8',
      backgroundColor: 'rgba(0, 150, 255, 0.15)',
      color: '#000',
    },"cell-style":_vm.cellStyle,"height":"250","border":""}},[(!_vm.inxHide)?_c('el-table-column',{attrs:{"type":"index","label":"序号","width":"80","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s((_vm.pager.curPage - 1) * _vm.pager.pageSize + scope.$index + 1)+" ")]}}],null,false,1667919716)}):_vm._e(),_vm._l((_vm.colsData),function(col){return _c('el-table-column',{key:col.code,attrs:{"label":col.name,"prop":col.code,"show-overflow-tooltip":"","align":col.align ? col.align : 'center',"width":_vm.width(col)},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(
            col.code == 'operations' &&
            row.operations &&
            row.operations.length
          )?_vm._l((row.operations),function(item,i){return _c('el-link',{key:i,staticClass:"action-item",style:({ color: item.color }),attrs:{"type":item.type},on:{"click":function($event){return _vm.handleTableRow(row, item)}}},[_vm._v(_vm._s(item.name)+" ")])}):(col.type === 'link')?_c('el-link',{staticClass:"link",attrs:{"type":"primary"},on:{"click":function($event){return _vm.toDetail(row)}}},[_vm._v(_vm._s(row[col.code]))]):(col.code === 'isValid')?_c('span',[_vm._v(" "+_vm._s(row[col.code] == '1' ? '是' : '否')+" ")]):_c('span',[_vm._v(" "+_vm._s(row[col.code])+" ")])]}}],null,true)})}),_c('JjEmpty',{attrs:{"slot":"empty"},slot:"empty"})],2),(!_vm.pagerHide)?_c('div',{staticClass:"pager"},[_c('el-pagination',{attrs:{"background":"","layout":"total,sizes,prev,pager,next","current-page":_vm.pagerOpt.curPage,"page-sizes":_vm.pagerOpt.sizes,"page-size":_vm.pagerOpt.pageSize,"total":_vm.total},on:{"size-change":_vm.pagerSizeChange,"current-change":_vm.pagerCurrentChange,"update:currentPage":function($event){return _vm.$set(_vm.pagerOpt, "curPage", $event)},"update:current-page":function($event){return _vm.$set(_vm.pagerOpt, "curPage", $event)}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }