import { getMenus } from "@/api/login";
import { getToDoNum } from "@/api/toDoAndHelp";
import router from '@/router/index'
import { setStore, getStore } from "@/util/store";

const common = {
  state: {
    menus: [],
    activeId: '',
  },
  mutations: {
    menus: (state, menus) => {
      state.menus = menus
      setStore({ name: 'menus', content: menus || [] })
    },
    activeId: (state, id) => {
      state.activeId = id
      setStore({ name: 'activeId', content: id || '' })
    },
    nodeInfo: (state, curNode) => {
      state.nodeInfo = curNode
      setStore({ name: 'nodeInfo', content: curNode || {} })
    },
    toDoQuantity: (state, quantity) => {
      state.toDoQuantity = quantity
      setStore({ name: 'toDoQuantity', content: quantity || {} })
    },
  },
  actions: {
    async getMenu({ commit, rootGetters, state }, params) {
      try {
        return new Promise(async resolve => {
          const { code, data } = await getMenus()
          if (code == 200) {
            commit('menus', data)
            let curNode = {}
            let children1 = data[0].children;
            if (children1 && children1.length > 0) {
              curNode = children1[0];
            } else {
              curNode = data[0];
            }
            commit('activeId', curNode.id)
            commit('nodeInfo', curNode)
            router.push({ path: curNode.routerPath });
            resolve(curNode)
          }
        })
      } catch (_) { }
    },
    async toDoNum({ commit, rootGetters, state }, params) {
      try {
        return new Promise(async resolve => {
          const { code, data } = await getToDoNum()
          if (code == 200) {
            commit('toDoQuantity', data)
            resolve(data)
          }
        })
      } catch (_) { }
    },
  }
}
export default common
