<template>
  <div class="Jj-video-play">
    <el-dialog
      :visible.sync="dialogVisible"
      width="60%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="video-box">
        <video
          class="video"
          id="videoDom"
          playsinline
          controls
          :src="previewVideoPath"
        >
          <source :src="previewVideoPath" />
          Video element is not supported in this browser.
        </video>
        <i
          class="el-icon-circle-close close-video-box"
          @click="handleClose"
        ></i>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "JjVideoPlay",
  components: {},
  props: {
    previewVideoPath: {
      type: String,
      require: true,
    },
    dialogVisible: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    handleClose() {
      let videoDom = document.getElementById("videoDom");
      videoDom.pause();
      videoDom.currentTime = 0;
      this.$emit("closePlayVideo");
    },
  },
  computed: {},
  watch: {},
};
</script>
<style scoped lang="scss">
.Jj-video-play {
  ::v-deep .el-dialog__header {
    display: none;
  }

  ::v-deep .el-dialog {
    background-color: transparent;
    box-shadow: none;
    margin: 0 !important;
  }

  ::v-deep .el-dialog__body {
    padding: 0;
    width: 100%;
    height: 100%;
  }
  .video-box {
    position: relative;
    width: 100%;
    height: 100%;
    .video {
      width: 100%;
      // height: 100%;
      // border-right-color: pink;
    }
    .close-video-box {
      position: absolute;
      top: 0;
      right: -50px;
      font-size: 45px;
      color: #ea2027;
      opacity: 0.8;
    }
  }
}
</style>
