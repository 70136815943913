import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
export default {
  install(Vue) {
    Vue.prototype.htmlToPDF = async function (name, title, match) {
      const ele = document.querySelector('#' + name)
      const eleW = ele.offsetWidth // 获得该容器的宽
      const eleH = ele.offsetHeight // 获得该容器的高

      const eleOffsetTop = ele.offsetTop // 获得该容器到文档顶部的距离
      const eleOffsetLeft = ele.offsetLeft // 获得该容器到文档最左的距离

      var canvas = document.createElement('canvas')
      var abs = 0

      const win_in =
        document.documentElement.clientWidth || document.body.clientWidth // 获得当前可视窗口的宽度（不包含滚动条）
      const win_out = window.innerWidth // 获得当前窗口的宽度（包含滚动条）

      if (win_out > win_in) {
        // abs = (win_o - win_i)/2;    // 获得滚动条长度的一半
        abs = (win_out - win_in) / 2 // 获得滚动条宽度的一半
      }

      canvas.width = eleW * 2 // 将画布宽&&高放大两倍
      canvas.height = eleH * 2

      var context = canvas.getContext('2d')

      context.scale(2, 2)

      context.translate(-eleOffsetLeft - abs, -eleOffsetTop)
      // 这里默认横向没有滚动条的情况，因为offset.left(),有无滚动条的时候存在差值，因此
      // translate的时候，要把这个差值去掉

      var pdf = new JsPDF('', 'pt', 'a4')
      let childrenBox = ele.children
      if (match) {
        childrenBox = Array.from(ele.children).filter((it) =>
          it.className.includes(match)
        )
      }
      for (let i = 0; i < childrenBox.length; i++) {
        // 循环传过来的Dom的字节点 每个子节点打印成一页pdf A4纸那么大
        const res = await html2Canvas(childrenBox[i], {
          dpi: 300,
          // allowTaint: true,  //允许 canvas 污染， allowTaint参数要去掉，否则是无法通过toDataURL导出canvas数据的
          useCORS: true, // 允许canvas画布内 可以跨域请求外部链接图片, 允许跨域请求。
          scale: 4, // 提升导出的文件的分辨率
        })
        var pageData = res.toDataURL('image/jpeg', 1.0)
        var contentWidth = res.width
        var contentHeight = res.height
        var imgWidth = 555.28
        var imgHeight = (552.28 / contentWidth) * contentHeight
        pdf.addImage(pageData, 'JPEG', 20, 20, imgWidth, imgHeight)
        if (i < childrenBox.length - 1) {
          pdf.addPage() // 避免最后多一个空白页
        }
      }

      pdf.save(`${title}.pdf`)
    }
  },
}
