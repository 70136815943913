// 项目管理
export let xmgl = [
  {
    path: 'zgxj',
    title: '最高限价',
    name: 'zgxj',
    component: () =>
      import(
        /* webpackChunkName: "projectManage" */ '@/pages/projectManage/zgxj/list.vue'
      ),
  },
  {
    path: 'zgxj-details',
    title: '最高限价详情',
    name: 'zgxj-details',
    redirect: '/pages/zgxj-details/first',
    component: () =>
      import(
        /* webpackChunkName: "projectManage" */ '@/pages/projectManage/zgxj/details/index.vue'
      ),
    children: [
      {
        path: 'first',
        title: '初审',
        name: 'first',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '@/pages/projectManage/zgxj/details/first.vue'
          ),
      },
      {
        path: 'detection',
        title: '造价检测',
        name: 'detection',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '@/pages/projectManage/zgxj/details/detection.vue'
          ),
      },
      {
        path: 'result',
        title: '检测结果',
        name: 'result',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '@/pages/projectManage/zgxj/details/result.vue'
          ),
      },
      {
        path: 'publicity',
        title: '登记公示',
        name: 'publicity',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '@/pages/projectManage/zgxj/details/publicity.vue'
          ),
      },
    ],
  },
  {
    path: 'jgjs',
    title: '竣工结算',
    name: 'jgjs',
    component: () =>
      import(
        /* webpackChunkName: "projectManage" */ '@/pages/projectManage/jgjs/list.vue'
      ),
  },
  {
    path: 'jgjs-details',
    title: '最高限价详情',
    name: 'jgjs-details',
    redirect: '/pages/jgjs-details/projectInfo',
    component: () =>
      import(
        /* webpackChunkName: "projectManage" */ '@/pages/projectManage/jgjs/details/index.vue'
      ),
    children: [
      {
        path: 'projectInfo',
        title: '初审',
        name: 'projectInfo',
        component: () =>
          import(
            /* webpackChunkName: "projectInfo" */ '@/pages/projectManage/jgjs/details/projectInfo.vue'
          ),
      },
      {
        path: 'costPreview',
        title: '造价检测',
        name: 'costPreview',
        component: () =>
          import(
            /* webpackChunkName: "costPreview" */ '@/pages/projectManage/jgjs/details/costPreview.vue'
          ),
      },
      {
        path: 'jgFilingForm',
        title: '竣工结算登记表',
        name: 'jgFilingForm',
        component: () =>
          import(
            /* webpackChunkName: "projectManage" */ '@/pages/projectManage/jgjs/details/jgFilingForm.vue'
          ),
      },
    ],
  },
];
