// 公告发布
export let notice = [
  {
    // 政策文件 /行业动态 ---列表
    path: 'notice-list/:node',
    title: '列表',
    name: 'notice-list',
    component: () =>
      import(
        /* webpackChunkName: "releaseNotice" */ '@/pages/releaseNotice/noticeList.vue'
      ),
  },
  {
    // 政策文件 /行业动态 ---详情
    path: 'notice-details',
    title: '详情',
    name: 'notice-details',
    component: () =>
      import(
        /* webpackChunkName: "releaseNotice" */ '@/pages/releaseNotice/noticeDetails.vue'
      ),
  },
];
