<template>
  <div class="edit-tool">
    <div style="border: 1px solid #ccc">
      <!-- 工具栏 -->
      <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" />
      <!-- 编辑器 -->
      <Editor
        style="overflow-y: hidden"
        :style="{ height: editorConfig.height || '350px' }"
        :defaultConfig="editorConfig"
        v-model="editorConfig.editText"
        @onChange="onChange"
        @onCreated="onCreated"
      />
    </div>
  </div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import COS from 'cos-js-sdk-v5'
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'JjEditorTool',
  components: { Editor, Toolbar },
  props: {
    editorParams: {
      type: Object,
      require: true
    },
    excludeKeys: {
      // 需要屏蔽的工具栏
      type: Array,
      require: false,
      default: null
    }
  },
  data() {
    return {
      cosClient: null,
      uploadFileType: '',
      editor: null,
      html: '',
      toolbarConfig: {
        // toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
        excludeKeys: ['fullScreen'] /* 隐藏哪些菜单 */
      },
      editorConfig: {
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          // 图片上传
          uploadImage: {
            server: '/dev-api/common/upload',
            timeout: 5 * 1000, // 5s 超时时间
            fieldName: 'custom-fileName',
            //选择文件时的类型限制，默认为['image/*'] 如不想限制，则设置为 []
            allowedFileTypes: ['image/*'],
            metaWithUrl: true, // 参数拼接到 url 上
            maxFileSize: 1000 * 1024 * 1024, //1g //设置大点 不然图片过大会报错
            base64LimitSize: 1000000 * 1024 // 1g 以下插入 base64
            /***  有上传图片和视频的接口之后使用下面自定义上传，删除上面的代码  ***/
            // customUpload: async (file, insertFn) => {  // 自定义上传
            //   let resultUrl = await this.handlePreview(file, file.name, 'Image')
            //   insertFn(resultUrl)
            // }
          },
          // 上传视频
          uploadVideo: {
            customUpload: async (file, insertFn) => {
              let resultUrl = await this.handlePreview(file, file.name, 'Video')
              insertFn(resultUrl)
            }
          }
        }
      }
    }
  },
  created() {
    this.createCOS()
  },
  watch: {
    editorParams: {
      deep: true,
      immediate: true,
      handler(v) {
        this.editorConfig = Object.assign(v, this.editorConfig)
        // console.log(this.editorConfig)
      }
    },
    excludeKeys: {
      deep: true,
      immediate: true,
      handler(n) {
        if (n) this.toolbarConfig.excludeKeys = [...this.toolbarConfig.excludeKeys, ...n]
      }
    }
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 【注意】一定要用 Object.seal() 否则会报错
      // console.log('编辑器参数', this.editor.getConfig(), '工具栏参数', this.editor.getAllMenuKeys())
    },
    onChange(editor) {
      // console.log('onChange', editor.getHtml()) // onChange 时获取编辑器最新内容 this.html=editor.getHtml()
      this.$emit('changeHtml', this.editorConfig.editText)
    },
    // 文件上传事件。
    handlePreview(files, name, type) {
      return new Promise(() => {
        this.uploadFileType = type
        // console.log('上传图片', files)
        const { size, name } = files
        if (!name) return
        const suffix = name.split('.')[name.split('.').length - 1]
        const uuid = uuidv4()
        let filePathUrl = 'functionWarehouse/MerchantableGoods'
        const saveName = filePathUrl + `/${uuid}.${suffix}`
        // 上传文件到腾讯云 -- 可以更改上传到后端接口
        return
        this.cosClient.uploadFile(
          {
            Bucket: 'user-key', // 填入您自己的存储桶，必须字段
            Region: 'user-area', // 存储桶所在地域，例如ap-beijing，必须字段
            Key: saveName, // 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段
            SliceSize: 1024 * 1024 * 5, // 触发分块上传的阈值，超过5MB使用分块上传，非必须
            Body: files, // 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象
            onTaskReady: function (taskId) {
              /* 非必须 */
              // console.log(taskId);
            },
            onProgress: function (progressData) {
              /* 非必须 */
              // console.log(JSON.stringify(progressData));
            },
            onFileFinish: function (err, data, options) {
              /* 非必须 */
              console.log(options.Key + '上传' + (err ? '失败' : '完成'))
            }
          },
          (err, data) => {
            // 上传成功-处理函数
            const { statusCode, ETag, Location, RequestId } = data
            if (statusCode == 200) {
              const file_obj = {
                fileMsg: files,
                cosMsg: data
              }
              // console.log('file_obj', file_obj)
              this.getImgMsg(file_obj)
            } else {
              console.log(err)
            }
          }
        )
      })
    },
    createCOS() {
      // 创建COS对象
      this.cosClient = new COS({
        SecretId: 'SecretId',
        SecretKey: 'SecretKey'
      })
    },
    // 上传成功后数据回显
    getImgMsg(img) {
      const { size, name, type } = img.fileMsg
      const { Location, ETag, RequestId } = img.cosMsg
      const param = {
        id: '',
        fileSize: size,
        fileName: name,
        fileKey: 'https://' + Location,
        md5: JSON.parse(ETag),
        hash: JSON.parse(ETag),
        crc32: RequestId,
        fileItem: '2',
        fileType: type
      }
      console.log('getImgMsg', param)

      // 数据回显 - 关键是param.fileKey返回的文件路径
      let addHtml = ''
      if (this.uploadFileType === 'Video') {
        addHtml = `<div data-w-e-type="video" data-w-e-is-void>
                        <video poster="" controls="true" width="auto" height="auto"><source src="${param.fileKey}" type="video/mp4"/></video>
                     </div>`
      } else {
        // this.uploadFileType === 'Image'
        addHtml = `<p><img src="${param.fileKey}" alt="" data-href="" style=""/></p>`
      }

      this.html += addHtml
      console.log('数据回显', this.html)
    }
  },
  mounted() {},
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁 editor ，重要！！！
  }
}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped lang="scss">
.edit-tool {
  width: 100%;
  ::v-deep .w-e-modal button {
    line-height: 32px;
    padding: 0 15px;
  }
  ::v-deep .w-e-modal {
    top: 0 !important;
  }
}
</style>
