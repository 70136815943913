// 获取未来日期和星期几 格式如[{date:'8月3号',week:'星期四'},{date:'8月5号',week:'星期五'}]
//settings 推后一天  numDays获取几天
import { api as viewerApi } from 'v-viewer';
import { Message, MessageBox } from 'element-ui';
import moment from 'moment';
import axios from 'axios';

//获取按钮权限
export const findButtons = (codesToFind) => {
  const buttons = [
    { code: 'revoke', color: '#409EFF', name: '撤回' },
    { code: 'submit', color: '#409EFF', name: '提交' },
    { code: 'audit', color: '#E6A23C', name: '受理' },
    // { code: 'edit', color: '#67C23A', name: '修改' },
    { code: 'back', color: '#f56c6c', name: '返回' },
  ];
  return buttons.filter((button) => codesToFind.includes(button.code));
};

// 状态
export const statusIcon = (status) => {
  switch (status) {
    case 0:
      return require('@/assets/images/status/wtj.png');
    case 1:
      return require('@/assets/images/status/dsl.png');
    case 2:
      return require('@/assets/images/status/ytg.png');
    case 3:
      return require('@/assets/images/status/yth.png');
    default:
      return '';
  }
};

// 获取早中晚
export const setPeriod = () => {
  let hour = moment(new Date()).hour();
  let time = '';
  if (hour < 8) {
    time = '早上好';
  } else if (hour >= 8 && hour < 11) {
    time = '上午好';
  } else if (hour >= 11 && hour < 14) {
    time = '中午好';
  } else if (hour >= 14 && hour < 18) {
    time = '下午好';
  } else {
    time = '晚上好';
  }
  return time;
};

//身份证中间*号表示
export const maskIDCard = (idCard) => {
  if (idCard && idCard.length > 7) {
    let prefix = idCard.substring(0, 3); // 取前3位
    let suffix = idCard.substring(idCard.length - 4); // 取后4位
    let maskedPart = '*'.repeat(idCard.length - 7); // 中间部分用星号代替
    return prefix + maskedPart + suffix;
  } else {
    return idCard; // 如果身份证号码过短，直接返回原字符串
  }
};

//获取地址栏上的参数 返回对象
export const getURLParams = () => {
  let url = window.location.href;
  let params = {};
  let queryString = url.split('?')[1];
  if (queryString) {
    let pairs = queryString.split('&');
    for (let pair of pairs) {
      let [key, value] = pair.split('=');
      params[key] = decodeURIComponent(value);
    }
  }
  return params;
};

// 图片文件下载
export const downloadUrl = async (url, fileName) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
// 通过文件流格式数据导出excel表格
export const downloadBinaryFile = (url, params, fileName) => {
  MessageBox.confirm('确认导出该列表?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  })
    .then(() => {
      axios
        .post(url, params, { responseType: 'blob' })
        .then((response) => {
          if (response.status === 200) {
            const blobType = 'application/vnd.ms-excel';
            const blobObj = new Blob([response.data], { type: blobType });
            const downloadLink = document.createElement('a');
            let href = window.URL || window.webkitURL || window.moxURL; // 浏览器兼容
            href = href.createObjectURL(blobObj);
            downloadLink.href = href;
            downloadLink.download = `${fileName}.xlsx`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            window.URL.revokeObjectURL(href);
          }
        })
        .catch((error) => {
          Message.warning(
            error.response.data.message || '文件导出错误，请联系管理员'
          );
        });
    })
    .catch(() => { });
};

// 图片预览
export const showViewImgDialog = (imgSrc) => {
  let images = [{ 'data-source': '' }];
  if (['', null, undefined].includes(imgSrc)) {
    Message.warning('请先选择要查看的图片'); // 这里改成自己的消息弹框提示组件
    return false;
  }
  images[0]['data-source'] = imgSrc;
  viewerApi({
    options: {
      toolbar: true,
      url: 'data-source',
      initialViewIndex: 0,
    },
    images: images,
  });
};

//数组转字符串
export function convertStr(data) {
  Object.keys(data).forEach((key) => {
    if (key !== 'buttons' && data[key]) {
      if (
        Array.isArray(data[key]) &&
        data[key].some((el) => typeof el !== 'object')
      ) {
        data[key] = data[key].join(';');
      }
    }
  });
}

//字符串转数组
export function convertArr(form, groups) {
  groups?.forEach((item) => {
    item.fields.forEach((el) => {
      if (el.show && (el.type == 'select-multiple' || el.type == 'cascader')) {
        if (form[el.code] && form[el.code].includes(';')) {
          form[el.code] = form[el.code].split(';');
        } else {
          form[el.code] = form[el.code] ? [form[el.code]] : [];
        }
      }
    });
  });
  return form;
}

// 提交校验附件
export const annexVerify = (data) => {
  let arr = [];
  data.map((item) => {
    if (item.required && !item.filePath) {
      arr.push(item.fileType);
    }
  });
  return arr.join(' 、');
};

// 防抖
export function debounce(func, wait = 200) {
  let timeout;
  return function (...args) {
    const context = this;

    if (timeout) {
      this.$message.warning('请勿连续点击！');
      return;
    }

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(context, args); // 执行原函数
      timeout = null;
    }, wait);
  };
}

// 获取单位 亿  万  元
export function getUnit(amount, type) {
  if (typeof amount !== 'number') {
    throw new Error('Amount must be a number.');
  }

  const thresholds = [
    { value: 1e8, label: '亿' },
    { value: 1e4, label: '万' },
    { value: 1, label: '元' },
  ];

  let formattedAmount = amount;
  let unitLabel = '';

  for (let i = 0; i < thresholds.length; i++) {
    if (Math.abs(amount) >= thresholds[i].value) {
      formattedAmount = amount / thresholds[i].value;
      unitLabel = thresholds[i].label;
      break;
    }
  }

  const formatter = new Intl.NumberFormat('zh-CN', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  if (type === 'num') {
    return `${formatter.format(formattedAmount)}`.trim();
  } else if (type === 'unit') {
    return `${unitLabel}`.trim();
  } else {
    return `${formatter.format(formattedAmount)} ${unitLabel}`.trim();
  }
}

// 时间限制
export function setDisabledDate(time, cur, form) {
  // 开始时间不能大于当前时间
  if (
    (cur.code === 'startTime' || cur.code === 'startYear') &&
    new Date(time).getTime() - new Date().getTime() > 0
  ) {
    Message.warning(`${cur.name}必须小于当前时间`);
    form[cur.code] = '';
    return;
  }
  // 限制开始、结束时间
  if (cur.limit && form[cur.limit]) {
    if (cur.limit === 'startTime') {
      // 当前项时间取值受限于开始时间
      if (new Date(time).getTime() - new Date(form[cur.limit]).getTime() <= 0) {
        Message.warning(`${cur.limitName}必须大于${cur.name}`);
        form[cur.code] = '';
        return;
      }
    } else {
      // 当前项时间取值受限于结束时间
      if (new Date(time).getTime() - new Date(form[cur.limit]).getTime() >= 0) {
        Message.warning(`${cur.limitName}必须大于${cur.name}`);
        form[cur.code] = '';
        return;
      }
    }
  }
}

// 计算两个时间点之间的持续时间
export function parseDateTime(dateTimeStr) {
  const [datePart, timePart] = dateTimeStr.split(' ');
  const [year, month, day] = datePart.split('-').map(Number);
  const [hours, minutes, seconds] = timePart.split(':').map(Number);
  return new Date(year, month - 1, day, hours, minutes, seconds); // 月份从0开始计数
}

export function formatDuration(duration) {
  const days = Math.floor(duration / (1000 * 60 * 60 * 24));
  duration %= 1000 * 60 * 60 * 24;
  const hours = Math.floor(duration / (1000 * 60 * 60));
  duration %= 1000 * 60 * 60;
  const minutes = Math.floor(duration / (1000 * 60));

  // let formattedDuration = '';
  // if (days > 0) {
  //   formattedDuration += `${days}天`;
  // }
  // if (hours > 0 || (days === 0 && minutes > 0)) {
  //   formattedDuration += `${hours}小时`;
  // }
  // // if (minutes > 0) {
  // //   formattedDuration += `${minutes}分`;
  // // }

  return `约${days}天${hours}小时`;
}

export function calculateDuration(dateStr1, dateStr2) {
  let date1 = parseDateTime(dateStr1);
  let date2 = parseDateTime(dateStr2);
  // 确保date1是较早的时间，date2是较晚的时间
  if (date2 < date1) return ''
  if (date1 > date2) {
    [date1, date2] = [date2, date1];
  }
  // 计算持续时间
  const duration = date2.getTime() - date1.getTime();
  // 格式化持续时间
  return formatDuration(duration);
}
