// 标准
export let standard = [
    {
        path: 'detectStandard',
        title: '检测标准',
        name: 'detectStandard',
        component: () =>
            import(
            /* webpackChunkName: "detectStandard" */ '@/pages/standard/detectStandard.vue'
            ),
    },
    {
        path: 'periodicalList',
        title: '期刊列表',
        name: 'periodicalList',
        component: () =>
            import(
            /* webpackChunkName: "periodicalList" */ '@/pages/standard/materialPrice/periodicalList.vue'
            ),
    },
    {
        path: 'materialList',
        title: '期刊列表',
        name: 'materialList',
        component: () =>
            import(
            /* webpackChunkName: "materialList" */ '@/pages/standard/materialPrice/materialList.vue'
            ),
    },
];