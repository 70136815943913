<template>
  <!-- 系统头部通知消息提醒 -->
  <div class="message" :class="{ 'scale-out': out }">
    <div class="tips">
      <img class="laba" :src="laba" alt="" />
      <p><JjTextTooltip :content="message"></JjTextTooltip></p>
      <span class="jump" @click="jumpMsgList"> 查看详情>></span>
      <i class="el-icon-close" @click="closeMsg"></i>
    </div>
  </div>
</template>
<script>
import { setStore, getStore, removeStore } from '@/util/store';
export default {
  name: 'TopMsg',
  components: {},
  props: {
    msg: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      out: false,
      message: '',
      msgObj: {},
      laba: require('@/assets/images/icon/laba.png'),
    };
  },
  computed: {},
  watch: {
    msg: {
      immediate: true,
      deep: true,
      handler(msg) {
        this.msgObj = msg;
        this.message = `来自【${msg.userName}】的消息！`;
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    jumpMsgList() {
      this.closeMsg();
      let params = { id: this.msgObj.businessId };
      // 1 2企业信息
      if (this.msgObj.msgType == '1' || this.msgObj.msgType == '2') {
        params.nodeName = this.msgObj.msgType == '2' ? '人员信息' : '企业信息';
        setStore({ name: 'tableRow', content: params });
        this.$router.push('/pages/basicDetails');
      }
      // 最高限价
      if (this.msgObj.msgType == '3') {
        setStore({ name: 'tableRow', content: params });
        this.$router.push('/pages/zgxj-details');
      }
      // 竣工结算
      if (this.msgObj.msgType == '4') {
        setStore({ name: 'tableRow', content: params });
        this.$router.push('/pages/jgjs-details');
      }
    },
    closeMsg() {
      this.out = true;
      setTimeout(() => {
        this.$store.commit('toDoMsg', '');
        removeStore({ name: 'toDoMsg' });
      }, 1000);
    },
  },
};
</script>
<style scoped lang='scss'>
.message {
  display: inline-table;
  width: auto;
  padding: 10px 16px;
  border-radius: 50px;
  // font-size: 16px;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 12px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  animation: fall 1.5s ease-in-out forwards; /* 动画名称、持续时间、缓动函数、填充模式 */
  .tips {
    display: flex;
    align-items: center;
    position: relative;
    p {
      max-width: 700px;
    }
    .laba {
      width: 22px;
      margin-right: 8px;
    }
    .name {
      color: #1e90ff;
    }
  }
  .jump {
    color: #1e90ff;
    cursor: pointer;
    margin-left: 20px;
  }
  .el-icon-close {
    color: #f56c6c;
    margin-left: 20px;
    font-size: 20px;
  }
}
@keyframes fall {
  0% {
    transform: translateY(-100px); /* 初始位置 */
  }
  100% {
    transform: translateY(0); /* 最终位置 */
  }
}
.scale-out {
  -webkit-animation: scale-out 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: scale-out 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
@-webkit-keyframes scale-out {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    opacity: 1;
  }
}
@keyframes scale-out {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    opacity: 1;
  }
}
</style>