<template>
  <div class="user-main">
    <div class="inquire_after">
      <span>{{ setPeriod() }}: {{ userInfo?.nickName || '' }}</span>
    </div>
    <div class="nav_top_tool">
      <div
        class="tool_item"
        v-for="(item, index) in toolList"
        :key="index"
        @click="handleTool(item)"
      >
        <div class="tool_icon"><img :src="item.icon" alt="" /></div>
        <div>{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { setPeriod } from '@/util/utils';
import { closeWebSocket } from '@/util/webSokt';
import { removeStore } from '@/util/store';
import { logout } from '@/api/login';
export default {
  components: {},
  props: {},
  data() {
    return {
      setPeriod,
      updatePeriod: null,
      user: '管理员',
      toolList: [
        // {
        //   icon: require("@/assets/images/icon/home_icon.png"),
        //   name: "首页",
        //   code: "home",
        // },
        // {
        //   icon: require("@/assets/images/icon/wait_icon.png"),
        //   name: "待办",
        //   code: "wait",
        //   badge: true,
        // },
        {
          icon: require('@/assets/images/icon/help_icon.png'),
          name: '帮助',
          code: 'help',
        },
        {
          icon: require('@/assets/images/icon/service_icon.png'),
          name: '客服',
          code: 'service',
        },

        {
          icon: require('@/assets/images/icon/safe_icon.png'),
          name: '安全',
          code: 'safe',
        },
        {
          icon: require('@/assets/images/icon/logout_icon.png'),
          name: '退出',
          code: 'logoutSystem',
        },
      ],
    };
  },
  computed: {
    ...mapState(['userInfo']),
  },
  watch: {},
  created() {
    setPeriod();
  },
  mounted() {
    this.updatePeriod = setInterval(() => {
      setPeriod();
    }, 600000);
  },
  destroyed() {
    clearInterval(this.updatePeriod);
  },
  methods: {
    handleTool(item) {
      this[item.code] && this[item.code]();
    },

    home() {
      this.$router.push('/pages/home');
    },
    help() {
      this.$router.push('/help');
    },
    service() {
      window.open(
        'https://tccc.qcloud.com/web/im/chat/?webAppId=de4c1a1043e99aaa0a43e8206fbc0a22'
      );
    },
    wait() {
      // this.$store.commit('toDoMsg', '');
      // removeStore({ name: 'toDoMsg' });
      // this.$router.push('/toDo');
    },
    safe() {
      this.$router.push('/pages/safety');
    },

    //退出
    async logoutSystem() {
      const flag = await this.$asyncConfirm('确定退出此系统吗?');
      if (flag == 'confirm') {
        let { code, data } = await logout();
        if (code == 200) {
          this.$store.commit('toDoMsg', '');
          // 清除localStorage中的所有项
          localStorage.clear();
          // 清除sessionStorage中的所有项
          sessionStorage.clear();
          this.$router.push('/');
          closeWebSocket();
        }
      }
    },
  },
};
</script>
<style scoped lang='scss'>
.user-main {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding-bottom: 2px;
  .inquire_after {
    font-size: 18px;
  }
  .nav_top_tool {
    cursor: pointer;
    display: flex;
    .tool_item {
      display: flex;
      align-items: center;
      margin-left: 20px;
      .tool_icon {
        display: flex;
        align-items: center;
        width: 20px;
        height: 20px;
        padding: 3px;
        background-color: #fff;
        border-radius: 100%;
        margin-right: 4px;
        img {
          width: 100%;
        }
      }
    }
  }
}
::v-deep .el-badge__content {
  border: none !important;
}
::v-deep .is-fixed {
  right: 6px !important;
}
</style>