<template>
  <div class="footer_btn">
    <template v-for="btn in btnAuth">
      <el-button
        :key="btn.code"
        slot="reference"
        size="mini"
        type="primary"
        :disabled="btnDsb && btn.code != 'back'"
        v-if="!btn.isShow"
        :style="{
          backgroundColor: btn.color,
          border: 'none',
        }"
        @click="actionHandler(btn.code, btn)"
      >
        <i v-if="nowCode == btn.code && btnDsb" class="el-icon-loading"></i>
        <span v-if="btn.code != 'edit'">{{ btn.name }}</span>
        <span v-else>{{ isEdit ? '保存' : btn.name }}</span>
      </el-button>
    </template>
  </div>
</template>
<script>
export default {
  name: 'JjFormFootBtn',
  components: {},
  props: {
    btnAuth: { type: Array, default: () => [] },
    btnDsb: { type: Boolean, default: false },
    isAdd: { type: Boolean, default: false },
  },
  data() {
    return {
      nowCode: '',
      isEdit: false,
    };
  },
  created() {
    if (this.isAdd) {
      this.btnAuth.map((it) => {
        // if (it.code == 'edit') {
        // this.isEdit = true;
        //   it.name = '保存';
        //   it.code = 'save';
        //   it.color = '#409EFF';
        // }
        if (it.code == 'submit') {
          it.isShow = true;
        }
      });
    }
  },
  mounted() {},
  computed: {},
  methods: {
    // 按钮点击
    actionHandler(code, item) {
      this.nowCode = code;
      let key = code;
      item.dsb = this.btnDsb;
      if (code === 'cancel') {
        item.isShow = true;
        this.setSaveBtn('cancel');
      }

      if (code == 'edit') {
        key = this.isEdit ? 'save' : code;
        this.setSaveBtn('edit');
      }
      this.$emit('actionHandler', key, item);
    },
    setSaveBtn(code) {
      this.isEdit = code === 'cancel' ? false : !this.isEdit;
      this.btnAuth.map((it) => {
        if (code === 'edit' && (it.code == 'submit' || it.code == 'cancel')) {
          it.isShow = !it.isShow;
        }

        if (code === 'cancel' && it.code == 'submit') it.isShow = false;
      });
    },
  },
  watch: {},
};
</script>
<style scoped lang="scss">
.footer_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 15px;
  // border-top: 1px solid #ccc;
  box-sizing: border-box;
  box-shadow: 0 -8px 20px 0 #dfdede;
  margin-top: 10px;
  // padding-top: 15px;
}

.download_count {
  height: 150px;
  padding: 5px;

  .title {
    font-size: 20px;
    font-weight: 700;
    color: #1e90ff;
    margin-bottom: 15px;
  }

  .count_wrap {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 16px;
    color: #000;

    .lable {
      font-weight: 600;
      margin-right: 20px;
    }

    .count {
      padding: 2px 12px;
      border-radius: 13px;
      font-size: 14px;
      color: #fff;
    }
  }
}
</style>
