<template>
  <div class="g-card">
    <div class="card-title">
      <slot name="title"></slot>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "JjCard",
  computed: {},
};
</script>

<style lang="scss" scoped>
.g-card {
  background: #fff;
  border-radius: 8px;
  overflow: auto;
  height: 100%;
  .card-title {
    // @extend %flex-left;
    position: relative;
    font-size: 16px;
    font-family: "黑体", "SimHei", "Arial", sans-serif;
    padding: 12px 20px;
    font-weight: bold;
    border-bottom: 1px solid #eee;
    background-color: #bfddfc;
  }
  .card-content {
    position: relative;
    // padding: 10px 20px;
    overflow-y: auto;
    transition: height 0.5s ease;
    .content-s {
      height: auto;
      transition: height 0.5s ease;
    }
  }
}
</style>
