export let home = [
    {
        path: 'dataSummary',
        title: '数据汇总',
        name: 'dataSummary',
        component: () =>
            import(/* webpackChunkName: "dataSummary" */ '@/pages/home/dataSummary/index.vue'),
    },
    {
        path: 'cityToDo',
        title: '省市待办事项',
        name: 'cityToDo',
        component: () =>
            import(/* webpackChunkName: "cityToDo" */ '@/pages/home/toDoMatters/city.vue'),
    },
    {
        path: 'countiesToDo',
        title: '区县待办事项',
        name: 'countiesToDo',
        component: () =>
            import(/* webpackChunkName: "countiesToDo" */ '@/pages/home/toDoMatters/counties.vue'),
    },
]