<template>
  <div class="top_tool">
    <div class="nav_group">
      <div
        class="nav_item"
        v-for="(nav, navIndex) in navList"
        :key="navIndex"
        :class="{ activeNav: queryList.curNav === nav.code }"
        @click="switchNavItem(nav)"
      >
        {{ nav.name }}
      </div>
    </div>
    <div class="serach_group" v-if="showSearch">
      <el-input
        v-model="queryList.searchKey"
        placeholder="请输入关键字进行搜索"
        @input="searchEvent"
        clearable
      >
        <template slot="append">
          <span @click="searchEvent('search')">搜索</span>
        </template>
      </el-input>
      <el-button size="mini" type="primary" @click="exportList" v-if="ifExport">
        导出
      </el-button>
      <!--  <el-button size="mini" type="warning" @click="searchEvent('reset')">
        重置
      </el-button> -->
    </div>
  </div>
</template>
<script>
import { setStore, getStore, removeStore } from '@/util/store';
import { throttle } from 'lodash';
export default {
  name: 'JjTableTopTool',
  components: {},
  props: {
    navList: { type: Array, required: true },
    queryList: { type: Object, required: true },
    showSearch: { type: Boolean, default: true },
    ifExport: { type: Boolean, default: false },
  },
  data() {
    return {
      searchKey: '',
      timer: null,
    };
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    switchNavItem(item) {
      this.queryList.curNav = item.code;
      setStore({ name: 'corpBasicNav', content: this.queryList.curNav });
      this.$emit('searchEvent', 'reset');
    },
    searchEvent(type) {
      // 清除之前的定时器
      if (this.timer) {
        clearTimeout(this.timer);
      }
      // 设置新的定时器，在用户停止输入n秒后执行搜索
      this.timer = setTimeout(() => {
        this.$emit('searchEvent', type);
      }, 700);
    },
    exportList: throttle(function () {
      this.$emit('exportList');
    }, 3000),
  },
};
</script>
<style scoped lang="scss">
.top_tool {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  .nav_group {
    display: flex;
    .nav_item {
      width: 96px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      font-size: 12px;
      color: #606266;
      border: 1px solid #1e90ff;
      border-left: none;
      box-sizing: border-box;
      cursor: pointer;

      &:first-child {
        border-left: 1px solid #1e90ff;
      }
      &:hover {
        color: #1e90ff;
      }
    }
    .activeNav {
      background-color: #1e90ff;
      transition: 0.5s;
      color: #fff !important;
    }
  }
  .serach_group {
    display: flex;
    align-items: center;
    ::v-deep .el-input__inner {
      width: 320px;
      border-radius: 0;
      border: 1px solid #9dceff;
    }
    ::v-deep .el-button--mini {
      padding: 0 15px;
      height: 28px;
      line-height: 28px;
      margin: 0 5px;
    }
  }
}
::v-deep .el-input__clear {
  color: #f56c6c;
  font-size: 18px;
}
::v-deep .el-input-group__append {
  color: #1e90ff;
  cursor: pointer;
}
::v-deep .el-input input::-webkit-input-placeholder {
  color: #666;
}
</style>
