import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { getStore } from '@/util/store'


Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const Router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 路由守卫
Router.beforeEach((to, from, next) => {
  let token = getStore({ name: 'token' })
  // 路由上添加了meta: { noAuth: true }是不需要认证的页面
  if (!to.matched.some(rd => rd.meta.noAuth)) {
    if (!token) {
      // 如果没有token，则重定向到首页
      next('/')
    } else {
      // 继续前往目标路由
      next();
    }
  } else {
    // 如果路由不需要认证，则直接前往
    next();
  }
});

export default Router
