<template>
  <div class="btn-upload">
    <template v-if="!showPercent">
      <el-upload
        action="#"
        :show-file-list="false"
        :auto-upload="false"
        :on-change="onChange"
        :on-error="onError"
      >
        <div class="addBtn"><i class="el-icon-upload"></i> 上传</div>
      </el-upload>
    </template>
    <div class="progress" v-if="showPercent">
      <div style="display: flex">
        <el-progress
          text-color="#fff"
          :text-inside="true"
          :stroke-width="18"
          :percentage="percentNum"
        ></el-progress>
        <i class="el-icon-error" @click="cancelUpload"></i>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { uploadFile, cancel } from "@/util/sliceUploaded";

export default {
  name: "JjBtnUpload",
  components: {},
  props: {
    uploadInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showPercent: false,
      percentNum: 0,
    };
  },
  computed: {
    ...mapState(["percent"]),
  },
  watch: {
    percent(v) {
      this.percentNum = v;
    },
  },
  created() {},
  mounted() {},
  methods: {
    // 失败
    onError(err, file, fileList) {
      setTimeout(() => {
        this.$message.warning("当前网络拥堵，请重新上传！");
        this.$store.commit("percent", -1);
      }, 1500);
    },
    onChange(file, fileList) {
      //限制文件名长度
      if (file.name.length > 64) {
        this.$message.warning(`文件名超过64位字符，请重新命名后上传！`);
        return false;
      }
      //限制格式
      let urlType = file.name.match(/[^.]+$/)[0].toLowerCase();
      if (!this.uploadInfo.limitingType.includes(urlType)) {
        this.$message.warning(
          `格式错误，请上传【 ${this.uploadInfo.limitingType} 】格式`
        );
        return false;
      }
      // 限制大小
      const size = file.size / 1024 / 1024 > Number(this.uploadInfo.fileSizi);
      if (size) {
        this.$message.warning(
          `文件大小不能超过【${this.uploadInfo.fileSizi}MB】!`
        );
        return false;
      }
      // 大于5M出现进度条
      if (file.size > 5 * 1024 * 1024) {
        this.showPercent = true;
      }
      let params = {};
      uploadFile(file, params).then(async (res) => {
        //切片未成功
        if (!res) {
          setTimeout(() => {
            this.showPercent = false;
            this.$message.warning("文件上传失败，请重新上传！");
          }, 1000);
          return;
        }
        this.percentNum = res.progress;
        setTimeout(() => {
          this.$emit("saveFile", Object.assign(res, this.uploadInfo));
          this.showPercent = false;
          this.$store.commit("percent", -1);
        }, 1000);
      });
    },
    //取消上传
    async cancelUpload() {
      const flag = await this.$asyncConfirm("确认取消上传吗?");
      if (flag == "confirm") {
        this.loading = true;
        this.showPercent = false;
        this.$store.commit("stopUpload", true);
        setTimeout(async () => {
          await cancel()
            .then((res) => {
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
            });
        }, 3000);
      }
    },
  },
  beforeDestroy() {
    this.$store.commit("stopUpload", true);
  },
};
</script>
<style scoped lang='scss'>
.btn-upload {
  width: 100%;
  height: 100%;
}
.addBtn {
  height: 34px;
  line-height: 34px;
  font-size: 14px;
}
.progress {
  width: 100%;
  height: 100%;
}
.el-icon-error {
  margin-left: 5px;
  font-size: 18px;
  color: #f56c6c;
}
.el-icon-upload {
  font-size: 16px;
}
::v-deep .el-progress {
  flex: 1;
}
::v-deep .el-upload {
  width: 100% !important;
}
</style>