import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Viewer from 'v-viewer';
import { initApi } from './api/gApi';
import UmyUi from 'umy-ui';
import 'umy-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
import VueClipboards from 'vue-clipboard2';
import * as echarts from 'echarts';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/styles/index.scss';
import 'viewerjs/dist/viewer.css';
import '@/components/component/index.js';
import 'default-passive-events';
import htmlToPDF from '@/util/htmlToCanvas.js';
// 引入样式

initApi(Vue);
Vue.use(ElementUI, Viewer);
Vue.use(VueClipboards);
Vue.use(htmlToPDF);
Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts;
Vue.use(UmyUi);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
