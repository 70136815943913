import Layout from '@/components/layout';
import { home } from './home';
import { xmgl } from './xmgl';
import { corpInfo } from './corpInfo';
import { zszb } from './zszb';
import { notice } from './notice';
import { standard } from './standard';
import backStage from './backStage';
export default [
  {
    path: '/pages',
    name: 'pages',
    component: Layout,
    children: [
      {
        path: 'safety',
        title: '安全',
        name: 'safety',
        component: () =>
          import(/* webpackChunkName: "safety" */ '@/pages/safety/index.vue'),
      },
      {
        path: 'changeInfo',
        title: '变更',
        name: 'changeInfo',
        component: () =>
          import(
            /* webpackChunkName: "changeInfo" */ '@/pages/changeInfo/changeDetail.vue'
          ),
      },
      ...home,
      ...xmgl,
      ...corpInfo,
      ...zszb,
      ...notice,
      ...standard,
      ...backStage,
    ],
  },
];
