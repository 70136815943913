export let corpInfo = [
  {
    // 公用的基础详情
    path: 'basicDetails',
    title: '基础详情',
    name: 'basicDetails',
    component: () => import('@/pages/corpInfo/basicDetails.vue'), // webpackChunkName: "corpInfo"
  },

  {
    // 管理员---企业信息列表
    path: 'corp-m',
    title: '企业信息列表',
    name: 'corpList',
    component: () => import('@/pages/corpInfo/businessInfo/adminList.vue'), // webpackChunkName: "corpInfo"
  },
  {
    // 用户---企业信息列表
    path: 'corp-u',
    title: '企业信息列表',
    name: 'user-details',
    component: () => import('@/pages/corpInfo/businessInfo/userDetails.vue'), // webpackChunkName: "corpInfo"
  },
  {
    // 管理员---人员信息列表
    path: 'staff-m',
    title: '人员信息列表',
    name: 'staffAdminList',
    component: () => import('@/pages/corpInfo/staffInfo/adminList.vue'), // webpackChunkName: "corpInfo"
  },
  {
    // 用户---人员信息列表
    path: 'staff-u',
    title: '人员信息列表',
    name: 'staffUserList',
    component: () => import('@/pages/corpInfo/staffInfo/userList.vue'), // webpackChunkName: "corpInfo"
  },
  {
    path: 'certificate',
    title: '人员证书',
    name: 'certificate',
    component: () => import('@/pages/corpInfo/certificate/index.vue'), // webpackChunkName: "corpInfo"
  },
]
