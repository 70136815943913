import { setStore, getStore } from '@/util/store'
import store from '@/store'
let messages = []
let socket = null
let heartbeatInterval = null // 心跳间隔定时器
let reconnectTimeout = 5000 // 重连超时时间
let isReconnecting = false // 是否正在尝试重新连接
let token = getStore({ name: 'token' })


export const initWebSocket = () => {
    let userId = getStore({ name: 'userInfo' })?.userCode
    if (socket || !userId) return;

    socket = new WebSocket(`http://118.195.175.80/ws/${userId}?token=${token}`);

    socket.onopen = () => {
        console.log('开始连接');
        startHeartbeat(); // 开始心跳检测
    };

    socket.onmessage = (event) => {
        const msg = JSON.parse(event.data);
        console.log('接收的消息', msg);
        store.commit('toDoMsg', msg)
        setStore({ name: 'toDoMsg', content: msg })
        // store.commit('toDoMsg', message.message+new Date().getTime())
        // messages.push(message); // 处理接收到的消息

    };

    socket.onerror = (error) => {
        reconnect(); // 发生错误时尝试重连
    };

    socket.onclose = (event) => {
        console.log('WebSocket  closed.', event);
        stopHeartbeat(); // 停止心跳检测
        if (userId) reconnect(); // 连接关闭时尝试重连
    };
}

export const startHeartbeat = () => {
    heartbeatInterval = setInterval(() => {
        if (socket && socket.readyState === WebSocket.OPEN) {
            console.log('开始心跳检测,30秒发送一次心跳包');
            socket.send('heartbeat'); // 发送心跳包
            // socket.send(JSON.stringify({ type: 'heartbeat' })); // 发送心跳包
        }
    }, 30000); // 每30秒发送一次心跳
}

export const stopHeartbeat = () => {
    if (heartbeatInterval) {
        console.log("停止心跳检测");
        clearInterval(heartbeatInterval);
        heartbeatInterval = null;
    }
}

export const reconnect = () => {
    if (isReconnecting) return; // 如果已经在尝试重连，则不重复触发
    isReconnecting = true;

    setTimeout(() => {
        isReconnecting = false;
        initWebSocket(); // 尝试重新连接
        console.log('发生错误时尝试重连:');
    }, reconnectTimeout);
}

export const closeWebSocket = () => {
    if (socket) {
        socket.close();
        socket = null;
    }
    // stopHeartbeat();
}