<template>
  <div class="Jj-empty">
    <img class="err-img" src="@/assets/images/bg/empty.png" alt="出错了" />
    <p class="err-msg">{{ msg }}</p>
  </div>
</template>
<script>
export default {
  name: 'JjEmpty',
  props: {
    msg: {
      type: String,
      default: '暂无数据',
    },
  },
  data() {
    return {}
  },
  watch: {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.Jj-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  .err {
    &-img {
      width: 200px;
      height: auto;
    }

    &-msg {
      line-height: 22px;
      padding: 10px 0;
      color: #999;
    }
  }
}
</style>
