
<template>
  <div>
    <div class="dynamic_card_list">
      <el-table
        :data="tableData.filter((it) => it.editFlag != 'del')"
        border
        style="width: 100%"
        :header-cell-style="headerStyle"
      >
        <el-table-column type="expand" v-if="columns.length > 3">
          <template slot-scope="scope">
            <el-form
              label-position="right"
              inline
              label-width="auto"
              class="demo-table-expand"
            >
              <div
                class="circulate_form_item"
                v-for="(cols, colNum) in columns.slice(3, columns.length)"
                :key="colNum"
              >
                <el-form-item :label="cols.name">
                  <span v-if="cols.type == 'radio'">
                    {{ scope.row[cols.code] == "1" ? "是" : "否" }}
                  </span>
                  <span v-else :title="scope.row[cols.code]"
                    >{{ scope.row[cols.code] }}
                  </span>
                </el-form-item>
              </div>
            </el-form>
          </template>
        </el-table-column>

        <el-table-column
          :label="tab.name"
          :prop="tab.code"
          v-for="(tab, num) in columns.slice(0, 3)"
          :key="num"
        >
          <template slot-scope="scope" v-if="scope.row.editFlag != 3">
            <span v-if="tab.type == 'radio'">
              {{ scope.row[tab.code] == "1" ? "是" : "否" }}
            </span>
            <span v-else>{{ scope.row[tab.code] }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="140px" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="editBiaoDaun(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="mini"
              @click="delBiaoDaun(scope.row)"
              style="color: #f56c6c"
              >移 除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "JiCardList",
  components: {  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
     
      columns: [], // 表格列数据
      tableData: [], //表格Data
      formData: {},
      headerStyle: {
        borderColor: "#b4d8fd",
        backgroundColor: "#e9f2fa",
        color: "#666",
      },
    };
  },
  watch: {
    fields: {
      immediate: true,
      deep: true,
      handler(n) {
        this.columns = n;
      },
    },
    list: {
      immediate: true,
      deep: true,
      handler(n) {
        this.tableData = n;
      },
    },
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    editBiaoDaun(row) {
      this.isOpen = true;
      this.formData = row;
    },
    delBiaoDaun(row) {
      row.editFlag = "del";
      this.$forceUpdate();
    },
    // biaoDuanFormData(data) {
    //   console.log("biaoDuanFormData", data);
    // },
    close() {
      this.isOpen = false;
    },
  },
};
</script>
<style scoped lang="scss">
.dynamic_card_list {
  width: 100%;
  height: 100%;
  .el-table--border {
    border: 1px solid #b4d8fd;
  }
  ::v-deep .el-form {
    display: flex;
    flex-wrap: wrap;
  }

  ::v-deep .el-form-item {
    width: 30% !important;
    white-space: nowrap;
    box-sizing: border-box;
  }

  ::v-deep .el-icon-arrow-right {
    margin-left: -10px;
    margin-top: -10px;
    font-size: 20px;
    color: #1e90ff;
  }
  ::v-deep .el-table td.el-table__cell {
    border-bottom: 1px solid #b4d8fd;
  }

  ::v-deep .cell {
    font-weight: 500 !important;
    color: #333 !important;
  }

  ::v-deep .el-form-item__content {
    line-height: 40px;
    position: relative;
    font-size: 14px;
    overflow: hidden;
    width: 351px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .circulate_form_item {
    width: 28%;
    margin: 0 20px;
  }

  .demo-table-expand {
    font-size: 0;
    padding-left: 10px;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
  }
}
</style>
