import { getStore } from '@/util/store'
const state = {
  activeId: getStore({ name: 'activeId' }),
  showAside: false,
  nodeInfo: getStore({ name: 'nodeInfo' }),
  userInfo: getStore({ name: 'userInfo' }),
  percent: 0,
  stopUpload: false,
  toDoMsg: getStore({ name: 'toDoMsg' }),
  toDoQuantity: getStore({ name: 'toDoQuantity' }),
  error401: null
}
export default state
