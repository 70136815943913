<template>
  <div class="jj-table">
    <el-table
      :data="tableData"
      :cell-style="{ borderColor: '#b9b8b8' }"
      :header-cell-style="{
        borderColor: '#b9b8b8',
        backgroundColor: 'rgba(0, 150, 255, 0.15)',
        color: '#000',
      }"
      height="250"
      border
    >
      <el-table-column type="index" label="序号" width="80" align="center">
        <template slot-scope="scope">
          {{ (pager.curPage - 1) * pager.pageSize + scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        v-for="col in colsData"
        :key="col.code"
        :label="col.name"
        :align="col.align ? col.align : 'center'"
        :width="width(col)"
      >
        <template v-if="col.children">
          <el-table-column
            v-for="it in col.children"
            :key="it.code"
            :label="it.name"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row[it.code] }}
            </template>
          </el-table-column>
        </template>

        <template v-if="!col.children" slot-scope="scope">
          {{ scope.row[col.code] }}
        </template>
      </el-table-column>
    </el-table>
    <div class="pager" v-if="pagerHide">
      <el-pagination
        layout="total,prev,pager,next"
        @size-change="pagerSizeChange"
        @current-change="pagerCurrentChange"
        :current-page.sync="pagerOpt.curPage"
        :page-size="pagerOpt.pageSize"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: 'JjMultilevelTable',
  components: {},
  props: {
    cols: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    pagerHide: {
      type: Boolean,
      default: false,
    },
    pager: {
      type: Object,
      default: () => {},
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      pagerOpt: { curPage: 1, pageSize: 5 },
    };
  },
  computed: {},
  watch: {
    cols: {
      deep: true,
      immediate: true,
      handler(data) {
        this.colsData = data;
      },
    },
    pager: {
      deep: true,
      immediate: true,
      handler(v) {
        this.pagerOpt = v;
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    width(col) {
      let w = col.width;
      if (col.code === 'operations') w = col.with || 200;
      return w;
    },
    pagerSizeChange(e) {
      this.pagerOpt.pageSize = e;
      this.$emit('pageChange', this.pagerOpt);
    },
    pagerCurrentChange(e) {
      this.pagerOpt.curPage = e;
      this.$emit('pageChange', this.pagerOpt);
    },
  },
};
</script>
<style scoped lang='scss'>
.jj-table {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}
.pager {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: transparent;
  border: 1px solid #b8b6b6;
  border-top: none;
  ::v-deep .btn-prev {
    background-color: transparent;
  }
  ::v-deep .btn-next {
    background-color: transparent;
  }
  ::v-deep .el-pager li {
    background-color: transparent;
  }
}
</style>