<template>
  <div class="Jj_annex_block">
    <div class="progress" v-if="showPercent">
      <div class="progress_row">
        <el-progress
          text-color="#fff"
          :text-inside="true"
          :stroke-width="18"
          :percentage="percentNum"
        ></el-progress>
        <i class="el-icon-error" @click="cancelUpload"></i>
      </div>
    </div>
    <template v-else>
      <div class="file_model" v-for="(item, index) in fileList" :key="index">
        <div class="file_box" v-if="item.filePath">
          <img class="jb" :src="jiaobiao" alt="" />
          <div v-if="isPDF(item.filePath)" class="iframe-box">
            <iframe
              class="iframe"
              :src="filePath(item.filePath)"
              frameborder="0"
            ></iframe>
          </div>
          <img v-else class="img" :src="iconShow(item.filePath)" alt="" />
          <div class="mask">
            <div class="tool_btn">
              <span
                @click="viewFile(item)"
                v-if="isPreview(item, item.filePath)"
                ><i class="el-icon-view"></i> 预览</span
              >
              <span @click="downloaFile(item)"
                ><i class="el-icon-download"></i> 下载</span
              >
              <span @click="delFile(item)" v-if="isUpload"
                ><i class="el-icon-delete"></i> 删除</span
              >
            </div>
          </div>
        </div>

        <div
          class="file_box upload_add"
          v-else
          @click="changeFile(item)"
          v-loading="
            loading && item.fileType == curFile.fileType ? true : false
          "
          element-loading-text="上传中..."
        >
          <i :class="isUpload ? 'el-icon-upload' : 'el-icon-lock'"></i>
          <p v-if="isUpload">点击上传</p>
          <p class="tips">格式：{{ item.limitingType?.join('、') }}</p>
          <p class="tips">大小：{{ '小于' + item.size + 'M' }}</p>
        </div>

        <div class="file_name" :class="{ required: item.required }">
          {{ item.fileType }}
        </div>
      </div>
    </template>

    <el-upload
      class="upload-demo"
      v-show="false"
      ref="uploadFile"
      action="#"
      :auto-upload="false"
      :on-change="onChange"
      :on-error="onError"
    >
    </el-upload>

    <!-- <el-dialog
      :append-to-body="true"
      :title="viewTitle"
      :visible.sync="viewVisible"
      width="80%"
    >
      <iframe
        :src="pdfUrl"
        style="width: 100%; height: 800px"
        frameborder="0"
      ></iframe>
    </el-dialog> -->
  </div>
</template>
<script>
import config from '@/config';
import { uploadFile, cancel } from '@/util/sliceUploaded';
import { showViewImgDialog, downloadUrl } from '@/util/utils';
const imgArr = ['jpg', 'png', 'jpeg'];
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'JjAnnexBlock',
  components: {},
  props: {
    annexList: { type: Array, default: () => [] },
    isUpload: { type: Boolean, default: false },
    otherPreview: { type: Boolean, default: false },
  },
  data() {
    return {
      config,
      viewVisible: false,
      viewTitle: '',
      pdfUrl: '',
      fileList: [],
      curFile: {},
      showPercent: false,
      percentNum: 0,
      loading: false,
      jiaobiao: require('@/assets/images/icon/jiaobiao.png'),
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapState(['percent']),
  },
  watch: {
    percent(v) {
      this.percentNum = v;
    },
    annexList: {
      deep: true,
      immediate: true,
      handler(list = []) {
        this.fileList = list;
      },
    },
  },
  beforeDestroy() {
    this.$store.commit('stopUpload', true);
  },
  methods: {
    isPDF(v) {
      return /\.pdf$/gi.test(v);
    },
    filePath(url) {
      return config.baseUrl + url;
    },
    // 失败
    onError(err, file, fileList) {
      setTimeout(() => {
        this.$message.warning('当前网络拥堵，请重新上传！');
      }, 1500);
    },
    onChange(file, fileList) {
      // 限制文件名长度
      if (file.name.length > 64) {
        this.$message.warning(`文件名超过64位字符，请重新命名后上传！`);
        return false;
      }
      // //限制格式
      let urlType = file.name.match(/[^.]+$/)[0].toLowerCase();
      let format = this.curFile.limitingType;
      if (!format.includes(urlType)) {
        this.$message.warning(
          `格式错误，请上传【${format.join('、')} 】格式的文件`
        );
        return false;
      }

      // // 限制大小
      const size = file.size / 1024 / 1024 > Number(this.curFile.size);
      if (size) {
        this.$message.warning(`文件大小不能超过【${this.curFile.size}MB】!`);
        return false;
      }
      // 大于5M出现进度条
      if (file.size > 5 * 1024 * 1024) {
        this.showPercent = true;
      }

      this.loading = true;
      let params = {};
      uploadFile(file, params).then(async (res) => {
        //切片未成功
        if (!res) {
          setTimeout(() => {
            this.showPercent = false;
            this.loading = false;
            this.$message.warning('文件上传失败，请重新上传！');
          }, 1000);
          return;
        }
        this.percentNum = res.progress;
        setTimeout(() => {
          let query = {
            fileId: res.fileId,
            fileName: res.originName,
            fileType: this.curFile.fileType,
            fileUrl: res.url,
            md5: res.md5,
          };
          this.$emit('saveFile', query);
          // this.$emit('saveFile', Object.assign(res, this.curFile));
          this.showPercent = false;
          this.loading = false;
          this.$store.commit('percent', -1);
          this.$message.success('上传成功！');
        }, 500);
      });
    },
    //取消上传
    async cancelUpload() {
      const flag = await this.$asyncConfirm('确认取消上传吗?');
      if (flag == 'confirm') {
        this.showPercent = false;
        this.loading = false;
        this.$store.commit('stopUpload', true);
        setTimeout(async () => {
          await cancel();
        }, 500);
      }
    },
    // 更新
    changeFile(item) {
      if (this.loading) return;
      this.curFile = item;
      if (this.isUpload) {
        this.$refs.uploadFile.$refs['upload-inner'].handleClick();
      }
    },
    //只有pdf和图片可以预览
    isPreview(item, url) {
      let type = url.match(/[^.]+$/)[0].toLowerCase();
      let img = imgArr.includes(type);
      if (type == 'pdf' || img) return true;
    },
    iconShow(url) {
      if (url.match(/[^.]+$/)) {
        let type = url.match(/[^.]+$/)[0].toLowerCase();
        if (imgArr.includes(type)) {
          return this.filePath(url);
        } else {
          switch (type) {
            case 'rar':
            case 'zip':
              return require('@/assets/images/file/zip.png');
            case 'excel':
              return require('@/assets/images/file/Excel.png');
            case 'word':
              return require('@/assets/images/file/word.png');
            default:
              return require('@/assets/images/file/default.png');
          }
        }
      }
    },
    // 下载
    downloaFile(item) {
      downloadUrl(this.filePath(item.filePath), item.fileType);
    },
    // 预览
    viewFile(item) {
      // if (this.otherPreview) {
      //   this.$emit('previewTable', item)
      //   return
      // }

      if (this.isPDF(item.filePath)) {
        this.pdfUrl = this.filePath(item.filePath);
        // this.viewTitle = item.fileType;
        // this.viewVisible = true;
        window.open(this.pdfUrl);
      } else {
        showViewImgDialog(this.filePath(item.filePath));
      }
    },
    // 删除
    delFile(item) {
      this.$confirm(`确定删除【${item.fileType}】附件吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        this.$emit('delFile', item);
      });
    },
  },
};
</script>
<style scoped lang="scss">
.Jj_annex_block {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 20px 0;
  .file_model {
    width: 160px;
    min-width: 160px;
    min-height: 215px;
    margin-right: 15px;
    cursor: pointer;
    .file_name {
      width: 100%;
      margin-top: 6px;
      text-align: center;
      color: #1e90ff;
    }
    .required::before {
      content: '*';
      color: #ea2027;
      position: relative;
      left: -3px;
      top: 3px;
    }
    .upload_add {
      display: flex;
      align-items: center;
      line-height: 16px;
      flex-direction: column;
      justify-content: center;
      color: #1e90ff;
      height: 100%;
      font-size: 14px;
      padding: 0 4px;
      transition: 0.3s;
      i {
        font-size: 32px;
      }
      .tips {
        font-size: 12px;
        margin-top: 6px;
        color: #999;
      }
      // &:hover {
      //   border: 1px dashed #1e90ff;
      //   transition: 0.3s;
      // }
    }
    .file_box:hover {
      .jb {
        display: none;
      }
    }
    .file_box {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      height: 180px;
      border: 1px dashed #1e90ff;
      overflow: hidden;
      position: relative;
      .jb {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        width: 26px;
      }
      .iframe-box {
        width: 100%;
        height: 180px;
        overflow: hidden;
        position: absolute;
        top: 0;
        .iframe {
          width: calc(100% + 21px);
          height: 100%;
          margin: -1px 0 0 -2px;
        }
      }
      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        padding: 0 10px;
      }
      .mask:hover {
        opacity: 1;
      }
      .mask {
        // display: none;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        transition: opacity 0.3s;
        opacity: 0;
        .tool_btn {
          width: 100%;
          height: 100%;
          padding: 20px 0;
          color: #fff;
          // font-size: 16px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
        }
      }
    }
  }
  .progress {
    width: 100%;
    height: 215px;
    .progress_row {
      display: flex;
      align-items: center;
      height: 100%;
    }
    ::v-deep .el-progress {
      flex: 1;
    }
    .el-icon-error {
      margin-left: 5px;
      font-size: 18px;
      color: #f56c6c;
    }
  }
}
::v-deep .el-dialog__body {
  padding: 0 !important;
}
</style>
