<template>
  <div class="page-wrapper">
    <div class="header-container">
      <div class="header-left">
        <p class="sys-name">陕西省建设工程造价数据监测平台</p>
      </div>
      <div class="header-right">
        <UserCenter></UserCenter>
      </div>
    </div>

    <div class="page-container">
      <div class="bg"></div>
      <div class="left-navBar">
        <LeftNavBar />
      </div>
      <div class="right-page">
        <transition name="my-animation">
          <router-view></router-view>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import LeftNavBar from './nav/leftNavBar.vue';
import UserCenter from './nav/userCenter.vue';
import { initWebSocket, closeWebSocket } from '@/util/webSokt';
export default {
  components: { LeftNavBar, UserCenter },
  props: {},
  data() {
    return {
      transition: false,
    };
  },
  computed: {},
  watch: {},
  created() {
    initWebSocket();
  },
  mounted() {},

  methods: {},
};
</script>
<style scoped lang='scss'>
.page-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .page-container {
    flex: 1;
    display: flex;
    overflow: auto;
    // background-color: #efeefd;
    position: relative;
    .bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url('~@/assets/images/bg/home_bg.jpg');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      opacity: 0.15;
      z-index: 1;
    }

    .left-navBar {
      width: auto;
      z-index: 9;
      // padding: 2px 0 0 2px;
      // background-color: rgba(238, 234, 234, 0.6);
      background-color: #001529;
      box-shadow: 8px 0 8px -5px rgba(0, 0, 0, 0.5); /* 右侧阴影 */
    }
    .right-page {
      flex: 1;
      overflow: hidden;
      margin: 20px 40px; //不可使用padding
      z-index: 9;
    }
  }
}
// .page-transition {
//   animation: pageFade 0.8s ease;
// }

// @keyframes pageFade {
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// }
</style>