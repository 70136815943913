export default [
  {
    path: 'explain',
    title: '帮助说明',
    name: 'explain',
    component: () =>
      import(
        /* webpackChunkName: "login" */ '@/pages/backStage/helpExplain/list.vue'
      ),
  },
  {
    path: 'access-log',
    title: '访问日志',
    name: 'accessLog',
    component: () =>
      import(
        /* webpackChunkName: "login" */ '@/pages/backStage/accessLog/index.vue'
      ),
  },
];
