<template>
  <div>
    <el-dialog
      title="受理"
      :visible.sync="dialog"
      width="auto"
      :before-close="close"
      :append-to-body="true"
    >
      <div class="centent">
        <el-form :model="form" label-width="82px">
          <el-form-item label="处理结果：">
            <el-radio-group v-model="form.pass" @input="radioCheng">
              <el-radio label="通过"></el-radio>
              <el-radio v-if="!stepBack" label="不通过"></el-radio>
              <el-radio v-if="stepBack" label="退回重新上传项目资料"></el-radio>
              <el-radio v-if="stepBack" label="退回重新上传成果文件"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="受理意见：">
            <el-input
              type="textarea"
              v-model="form.opinion"
              placeholder="请填写受理意见"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="msg-box">
          <i v-if="errorMsg" class="el-icon-warning"></i>&nbsp;
          <span>{{ errorMsg }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close" size="mini">取 消</el-button>
        <el-button type="primary" @click="confirm" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'JjAuditDialog',
  components: {},
  props: {
    auditDialog: {
      type: Boolean,
      default: false,
    },
    stepBack: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {},
      errorMsg: '',
      dialog: false,
    };
  },
  computed: {},
  watch: {
    auditDialog: {
      deep: true,
      immediate: true,
      handler(d) {
        console.log('6666', d);
        this.dialog = d;
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    radioCheng() {
      this.errorMsg = '';
    },
    close() {
      this.$emit('close');
    },
    confirm() {
      this.errorMsg = '';
      if (!this.form.pass) {
        this.errorMsg = '请选择处理结果！';
        return;
      }
      if (this.form.pass !== '通过' && !this.form.opinion) {
        this.errorMsg = '请填写不通过受理意见！';
        return;
      }
      this.$emit('auditFun', this.form);
    },
  },
};
</script>
<style scoped lang='scss'>
.centent {
  min-width: 400px;
}
.msg-box {
  color: #ea2027;
  margin-top: -10px;
}
::v-deep .el-dialog__body {
  padding: 10px 30px 0 30px;
}
::v-deep .el-textarea__inner {
  color: #333;
  border-radius: 0;
  border: 1px solid #9dceff;
}
</style>