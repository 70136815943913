<template>
  <div class="jj-table">
    <el-table
      :data="tableData"
      :header-cell-style="{
        borderColor: '#b9b8b8',
        backgroundColor: 'rgba(0, 150, 255, 0.15)',
        color: '#000',
      }"
      :cell-style="cellStyle"
      height="250"
      border
    >
      <el-table-column
        v-if="!inxHide"
        type="index"
        label="序号"
        width="80"
        align="center"
      >
        <template slot-scope="scope">
          {{ (pager.curPage - 1) * pager.pageSize + scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        v-for="col in colsData"
        :key="col.code"
        :label="col.name"
        :prop="col.code"
        show-overflow-tooltip
        :align="col.align ? col.align : 'center'"
        :width="width(col)"
      >
        <template slot-scope="{ row }">
          <template
            v-if="
              col.code == 'operations' &&
              row.operations &&
              row.operations.length
            "
          >
            <el-link
              :type="item.type"
              class="action-item"
              v-for="(item, i) in row.operations"
              :key="i"
              :style="{ color: item.color }"
              @click="handleTableRow(row, item)"
              >{{ item.name }}
            </el-link>
          </template>
          <el-link
            v-else-if="col.type === 'link'"
            class="link"
            type="primary"
            @click="toDetail(row)"
            >{{ row[col.code] }}</el-link
          >
          <!-- 操作栏按钮 -->

          <span v-else-if="col.code === 'isValid'">
            {{ row[col.code] == '1' ? '是' : '否' }}
          </span>
          <span v-else>
            {{ row[col.code] }}
          </span>
        </template>
      </el-table-column>
      <JjEmpty slot="empty" />
    </el-table>
    <div class="pager" v-if="!pagerHide">
      <el-pagination
        background
        layout="total,sizes,prev,pager,next"
        @size-change="pagerSizeChange"
        @current-change="pagerCurrentChange"
        :current-page.sync="pagerOpt.curPage"
        :page-sizes="pagerOpt.sizes"
        :page-size="pagerOpt.pageSize"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import config from '@/config'
export default {
  name: 'JjOrdinaryTable',
  components: {},
  props: {
    cols: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    pager: {
      type: Object,
      default: () => {},
    },
    total: {
      type: Number,
      default: 0,
    },
    pagerHide: {
      type: Boolean,
      default: false,
    },
    inxHide: {
      type: Boolean,
      default: false,
    },
    cellPadding: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      config,
      colsData: [],
      pagerOpt: { curPage: 1, pageSize: 10, sizes: [10, 20, 30, 50] },
      cellStyle: {
        borderColor: '#b9b8b8',
        padding: '',
      },
      filterImg: require('@/assets/images/icon/filter.png'),
    }
  },
  computed: {},
  watch: {
    cols: {
      deep: true,
      immediate: true,
      handler(data) {
        this.colsData = data
      },
    },
    pager: {
      deep: true,
      immediate: true,
      handler(v) {
        this.pagerOpt = v
      },
    },
  },
  created() {},
  mounted() {
    this.cellStyle.padding = this.cellPadding
  },
  methods: {
    width(col) {
      let w = col.width
      if (col.code === 'operations') w = col.width || 200
      return w
    },
    handleTableRow(row, btnRow) {
      this.$emit('handleTableRow', row, btnRow)
    },
    toDetail(row) {
      this.$emit('detail', row)
    },
    pagerSizeChange(e) {
      this.pagerOpt.pageSize = e
      this.$emit('pageChange', this.pagerOpt)
    },
    pagerCurrentChange(e) {
      this.pagerOpt.curPage = e
      this.$emit('pageChange', this.pagerOpt)
    },
  },
}
</script>
<style scoped lang="scss">
.jj-table {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  .action-item {
    padding: 0 5px;
  }
  .link {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis; /* 这会显示省略号 */
    white-space: nowrap; /* 防止文本换行 */
  }

  .pager {
    height: 48px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // background-color: #fff;
    border: 1px solid #b9b8b8;
    border-top: none;
  }
}
</style>
