<template>
  <div class="record_btn">
    <div @click="openRecord" style="display: flex; align-items: center">
      <span
        >受理记录&nbsp;
        <i class="el-icon-arrow-up" :class="{ show_record: recordPopover }"></i>
      </span>
      <img class="record_img" src="@/assets/images/icon/record.png" alt="" />
    </div>
    <i class="el-icon-caret-bottom" v-if="recordPopover"></i>
    <div class="record_list" :class="{ 'open-s': recordPopover }">
      <div class="submit_record" v-loading="logLoading">
        <el-timeline v-if="activities.length > 0">
          <el-timeline-item
            v-for="(activity, index) in activities"
            :key="index"
            :icon="timelineColor(activity).icon"
            :type="timelineColor(activity).type"
            :timestamp="activity.operationTime"
          >
            <div>
              <span :style="{ color: activity.color }">{{
                activity.actionName
              }}</span>
              <span style="color: #999; font-size: 12px; margin-left: 10px">
                {{ activity.operatorName }}</span
              >
            </div>
            <div>{{ activity.opinion }}</div>
          </el-timeline-item>
        </el-timeline>
        <template v-if="activities.length === 0">
          <JjEmpty :msg="'暂无受理记录'"></JjEmpty>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { logRecord } from '@/api/public';
export default {
  name: 'JjLogRecord',
  components: {},
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      activities: [],
      logLoading: false,
      recordPopover: false,
    };
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    closeRecord() {
      this.recordPopover = false;
    },
    openRecord() {
      this.recordPopover = !this.recordPopover;
      if (this.recordPopover) this.getLogRecord();
    },
    async getLogRecord() {
      this.logLoading = true;
      let { code, data } = await logRecord(this.id);
      if (code === 200) {
        this.logLoading = false;
        this.activities = data;
      } else {
        this.logLoading = false;
      }
    },
    timelineColor(item) {
      switch (item.action) {
        case 'submit':
          item.type = 'primary';
          item.icon = 'el-icon-s-promotion';
          item.color = '#409EFF';
          break;
        case 'back':
          item.type = 'danger';
          item.icon = 'el-icon-close';
          item.color = '#F56C6C';
          break;
        case 'pass':
          item.type = 'success';
          item.icon = 'el-icon-s-check';
          item.color = '#67C23A';
          break;
        case 'revoke':
          item.type = 'warning';
          item.icon = 'el-icon-refresh';
          item.color = '#E6A23C';
          break;
      }
      return item;
    },
  },
};
</script>
<style scoped lang="scss">
// 受理记录
.record_btn {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 8px;
  right: 40px;
  font-size: 16px;
  background: linear-gradient(135deg, #a1c4fd, #bee8fc);
  color: #409eff;
  padding: 3px 10px;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  .record_img {
    width: 40px;
    margin-left: 15px;
  }
  .el-icon-arrow-up {
    transition: 0.7s;
  }
  .show_record {
    transform: rotate(180deg);
    transition: 0.7s;
  }
  .el-icon-caret-bottom {
    position: absolute;
    bottom: 44px;
    right: 20px;
    color: #fff;
    z-index: 2;
    font-size: 26px;
  }
  .record_list {
    border-radius: 6px;
    min-width: 400px;
    max-height: 0;
    overflow-y: hidden;
    cursor: default;
    background: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 60px;
    right: 0;
    transition: max-height 0.5s ease; /* 定义过渡效果 */
    z-index: 1;
  }
  .record_list.open-s {
    max-height: 720px; /* 目标最大高度 */
    overflow-y: auto; /* 允许滚动 */
  }
}
.submit_record {
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  ::v-deep .el-timeline-item__node--large,
  ::v-deep .el-timeline-item__node--normal {
    width: 30px;
    height: 30px;
  }
  ::v-deep .el-timeline-item__wrapper {
    padding-left: 35px;
  }
  ::v-deep .el-timeline-item__tail {
    left: 12px;
  }
}
</style>
