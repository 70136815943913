import login from './login'
import pages from "./pages";
// import toDo from "./toDo"
import page404 from '@/components/error/404'
export default [
  ...login,
  ...pages,
  // ...toDo,
  {
    hidden: true,
    path: '*',
    component: page404
  }
]